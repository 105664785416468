<template>
    <button type="button" v-bind="$attrs" :class="['flex items-center gap-1 text-center rounded-full', icon, color, centeredIcon, { iconOnly: !$slots.default, outlined, small, large, wide, errorBorder: isError }]" @click="onClick" :data-testid="testid">
        <Spinner v-if="loading" />
        <icon v-if="prependIcon && icon && !loading && !centeredIcon" :icon="icon" class="icon mr-1" />
        <div v-if="$slots.default || centeredIcon" :class="['h3 w-full flex justify-center items-center textContainer', { 'mx-1 gap-05': icon }]">
            <icon v-if="icon && centeredIcon && prependIcon" :icon="icon" class="icon" />
            <slot></slot>
            <icon v-if="icon && centeredIcon && !prependIcon" :icon="icon" class="icon" />
        </div>
        <icon v-if="!prependIcon && icon && !centeredIcon" :icon="icon" class="ml-1 icon" />
    </button>
</template>

<script>
    import Spinner from "./Spinner.vue"

    export default {
        components: { Spinner },
        props: {
            icon: String,
            prependIcon: Boolean,
            color: {
                type: String,
                default: "red",
            },
            isError: {
                type: Boolean,
                default: false,
            },
            outlined: Boolean,
            small: Boolean,
            large: Boolean,
            wide: Boolean,
            loading: Boolean,
            centeredIcon: Boolean,
            testid: {
                type: String,
                required: false,
            },
        },
        methods: {
            onClick(e) {
                if (!this.loading) this.$emit("click", e)
            },
        },
    }
</script>

<style lang="scss" scoped>
    button {
        border: 1px solid black !important;
        padding: calc(#{var(--space-1)} - 2px) var(--space-2);
        min-width: 274px;
        &.red {
            background-color: var(--color-red) !important;
            border-color: var(--color-red) !important;
            color: var(--color-cream) !important;
            &.outlined {
                background-color: transparent !important;
                border-color: var(--color-black) !important;
                color: var(--color-black) !important;
            }
        }
        &.cream {
            background-color: var(--color-cream) !important;
            border-color: var(--color-cream) !important;
            color: var(--color-black) !important;
            &.outlined {
                background-color: transparent !important;
                border-color: var(--color-cream) !important;
                color: var(--color-cream) !important;
            }
        }
        &.errorBorder {
            border-color: var(--color-red) !important;
            &.outlined {
                border-color: var(--color-red) !important;
            }
        }
        @media (min-width: 960px) {
            &.red:hover {
                background-color: var(--color-dark-red) !important;
                border-color: var(--color-dark-red) !important;
                &.outlined {
                    background-color: var(--color-red) !important;
                    border-color: var(--color-red) !important;
                    color: var(--color-cream) !important;
                }
            }
            &.cream:hover {
                background-color: var(--color-red) !important;
                border-color: var(--color-red) !important;
                color: var(--color-cream) !important;
                &.outlined {
                    background-color: var(--color-cream) !important;
                    border-color: var(--color-cream) !important;
                    color: var(--color-black) !important;
                }
            }
        }
        &.white {
            background-color: white !important;
            border: 1px solid var(--color-grey) !important;
            color: var(--color-black) !important;
        }
        &.green {
            background-color: var(--color-green) !important;
            border: 1px solid var(--color-green) !important;
            color: var(--color-white) !important;
        }
        &:disabled,
        &:disabled.outlined {
            background-color: var(--color-grey) !important;
            border-color: var(--color-grey) !important;
            color: var(--color-cream) !important;
            &:hover {
                background-color: var(--color-grey) !important;
                border-color: var(--color-grey) !important;
                color: var(--color-cream) !important;
            }
        }
        &.iconOnly {
            max-height: 44px;
        }
        &.small {
            min-width: unset;
            width: auto;
        }
        &.large {
            min-width: unset;
            height: unset;
            padding: 16px var(--space-3);
            line-height: normal;
        }
        &.wide {
            min-width: unset;
            width: 100%;
            min-width: auto;
        }
        &.iconOnly {
            min-width: unset;
            line-height: 23px;
            padding: var(--space-1);
            &.large {
                padding: var(--space-2);
            }
        }
        .icon {
            // margin-top: -2px;
            color: inherit !important;
            width: auto;
            height: 24px;
        }
        .textContainer {
            white-space: inherit;
        }
    }
</style>
