// This nuxt plugin will initialiase the firebase app
// and inject the firebase authentication service into the context of the application.

// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app"

// These imports load individual services into the firebase namespace.
import "firebase/auth"

let env = (process.env["ENV"] || "staging").toLowerCase()
let configKey = ["staging", "production"].includes(env) ? env : "staging"

const firebaseConfig = require(`../firebase_config_${configKey}.json`)

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
const auth = app.auth()

export { auth }

export default (context, inject) => {
    inject("firebaseAuth", auth)

    return new Promise(resolve => {
        auth.onAuthStateChanged(async user => {
            if (user) {
                const token = await user.getIdToken()
                context.$axios.setToken(token)

                // TODO: these next two lines are no longer necessary
                // because firebase takes care of storing and updating the token
                // and the longlevity of it can be controlled by a firebase setting (it will pick the appropriate browser storage option)
                // window.sessionStorage.setItem("token", token)
                // window.sessionStorage.setItem("authenticated", true)

                context.store.commit("authentication/setUser", user)
                return resolve()
            } else {
                context.$axios.setToken(null)

                // TODO: these next two lines are no longer necessary
                // because firebase takes care of storing and updating the token
                // and the longlevity of it can be controlled by a firebase setting (it will pick the appropriate browser storage option)
                // window.sessionStorage.removeItem("token")
                // window.sessionStorage.removeItem("authenticated")

                context.store.dispatch("authentication/logout")
                return resolve()
            }
        })
    })
}
