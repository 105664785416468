const TIMING_ENABLED = true

export function timeStart(label) {
    if (!TIMING_ENABLED) {
        return
    }
    console.time(label)
}

export function timeEnd(label) {
    if (!TIMING_ENABLED) {
        return
    }
    console.timeEnd(label)
}

export function timeFunction(label, func) {
    return (...args) => {
        timeStart(label)
        try {
            return func(...args)
        } finally {
            timeEnd(label)
        }
    }
}

export function timeAsyncFunction(label, func) {
    return async (...args) => {
        timeStart(label)
        try {
            return await func(...args)
        } finally {
            timeEnd(label)
        }
    }
}
