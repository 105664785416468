interface RedirectDefinition {
    baseTo?: string
    pathTo: string
    additionalParams?: { [key: string]: any }
    keepParams?: boolean
}

export function applyRedirect(router: any, redirectDefinition: RedirectDefinition) {
    let url = compileRedirectUrl(redirectDefinition).href
    if (url.includes("http")) {
        window.location.href = url
    } else {
        router.push(url)
    }
}

export function compileRedirectUrl(redirectDefinition: RedirectDefinition) {
    let currentUrl = new URL(window.location as any)

    const builtRedirect = new URL(`${redirectDefinition.baseTo || currentUrl.origin}${redirectDefinition.pathTo || ""}`)
    if (redirectDefinition.keepParams !== false) {
        currentUrl.searchParams.forEach((value, key) => {
            builtRedirect.searchParams.set(key, value)
        })
    }
    if (redirectDefinition.additionalParams) {
        Object.entries(redirectDefinition.additionalParams).forEach(element => {
            if (element[1]) {
                builtRedirect.searchParams.set(element[0], element[1])
            } else {
                builtRedirect.searchParams.delete(element[0])
            }
        })
    }
    return builtRedirect
}
