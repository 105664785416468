<template>
    <div class="flex justify-between items-center border border-black rounded px-1 py-05" :class="{ 'py-[4px]': small }">
        <button @click="minus" class="flex items-center" testid="eshop.decrease_product_amount">
            <icon icon="mdi:minus" class="h-full h3" :class="{ h4: small }" />
        </button>
        <div class="text-red font-bold h3 px-2" :class="{ h4: small }" testid="eshop.product_quantity">{{ quantity }}</div>
        <button @click="plus" class="flex items-center" testid="eshop.increase_product_amount">
            <icon icon="mdi:plus" class="h-full h3" :class="{ h4: small }" />
        </button>
    </div>
</template>

<script>
    export default {
        name: "ProductQuantity",
        components: {},
        props: {
            value: Number,
            small: Boolean,
            bold: Boolean,
            min: {
                type: Number,
                required: false,
            },
            max: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                quantity: 1,
            }
        },
        computed: {},
        mounted() {
            this.quantity = this.$props.value
        },
        methods: {
            minus() {
                if (this.quantity > 1) {
                    this.quantity -= 1
                    this.$emit("input", this.quantity)
                } else {
                    this.$emit("zero")
                }
            },
            plus() {
                if ([null, undefined].includes(this.max) || this.quantity < this.max) {
                    this.quantity += 1
                    this.$emit("input", this.quantity)
                } else {
                    this.$emit("max")
                }
            },
        },
        watch: {
            value(newValue, oldValue) {
                this.quantity = newValue
            },
        },
    }
</script>

<style lang="scss" scoped>
    .quantityWrapper {
        border: 1px solid var(--color-black);
        border-radius: 20px;
    }
    .quantityMinus,
    .quantityAmount,
    .quantityPlus {
        padding: 0.4rem 0.5rem;
        background-color: var(--color-cream);
        height: 100%;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
    }
    .quantityMinus {
        border-right: none;
        border-radius: 20px 0 0 20px;
    }
    .quantityAmount {
        min-width: 35px;
        text-align: center;
        border-left: none;
        border-right: none;
        color: var(--color-red);
    }
    .quantityPlus {
        border-left: none;
        border-radius: 0 20px 20px 0;
    }
    .small {
        overflow: hidden;
        background-color: var(--color-cream);
        border-radius: 20px;
        border: 1px solid var(--color-grey);
    }
    small .quantityAmount {
        width: auto;
    }
    .small .quantityMinus,
    .small .quantityAmount,
    .small .quantityPlus {
        padding: 0;
        border: 0;
        border-radius: 0;
    }
    .small .quantityMinus {
        padding-left: 0.25rem;
    }
    .small .quantityPlus {
        padding-right: 0.25rem;
    }
    .bold {
        font-weight: bold;
        font-size: 18px;
    }
    @media (max-width: 960px) {
        .quantityAmount {
            width: 100%;
        }
    }
</style>
