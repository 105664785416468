<template>
    <div class="mainwrapper">
        <LayoutComponent :countryPopup="countryPopup">
            <template v-slot:header>
                <ShopNavbarWithCart v-if="cartContent > 0" />
                <ShopNavbar v-else />
            </template>
            <nuxt />
            <Footer />
        </LayoutComponent>
    </div>
</template>

<script lang="js">
    import Footer from "~/components/features/eshop/Footer.vue"
    import LayoutComponent from "../components/LayoutComponent"
    import ShopNavbar from "~/components/features/eshop/ShopNavbar.vue"
    import ShopNavbarWithCart from "~/components/features/eshop/ShopNavbarWithCart.vue"

    import CustomerVisitMixin from "~/components/mixins/CustomerVisitMixin"
    import PollSubscriptionMixin from "~/components/mixins/PollSubscriptionMixin"
    import ShopCartMixin from "~/components/mixins/ShopCartMixin"

    export default {
        name: "ShopLayout",
        components: { Footer, LayoutComponent, ShopNavbar, ShopNavbarWithCart },
        mixins: [CustomerVisitMixin(), PollSubscriptionMixin, ShopCartMixin],
        computed: {
            breakpointMenu() {
                return this.menu ? this.menu[this.$mq] : null
            },
            countryPopup() {
                return window.location.pathname.indexOf("/shop/delivery") === -1
            },
        },
    }
</script>

<style lang="scss" scoped>
    .mainwrapper {
        background-color: var(--color-cream) !important;
    }

    .headerMargin {
        margin-bottom: 4.5rem;
    }

    .headerMargin.bannerMargin {
        margin-bottom: 6.7rem;
    }
</style>

<style lang="scss">
    @media (min-width: 1904px) {
        .container {
            max-width: 1240px;
        }
    }

    :root {
        --red: #fc4628;
    }

    body {
        overflow-y: auto;
        overflow-x: hidden;
        font-family: "GT-Walsheim";
    }
</style>
