<template>
    <div>
        <div class="fixed z-30 bg-black bg-opacity-20" :class="{ 'inset-0': value }" @click="$emit('input', false)"></div>
        <div class="transition-all duration-300 z-40 shadow fixed top-0 h-full md:w-[400px]" :class="[right ? '-right-[150vw] md:-right-[400px]' : '-left-[150vw] md:-left-[400px]', right ? { '!right-0': value } : { '!left-0': value }, notFullMobile ? 'w-4/5' : 'w-full']">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: Boolean,
            right: Boolean,
            notFullMobile: Boolean,
        },
        watch: {
            "$route.path"() {
                if (this.value) this.$emit("input", false)
            },
        },
    }
</script>
