<template>
    <Menu v-model="menu" :top="top" left0 right0 :class="block ? 'w-full' : ''">
        <template v-slot:activator>
            <button type="button" @click="menu = !menu" :class="['flex items-center justify-between border rounded-full px-1 py-[10px] hover:border-transparent', buttonStyles, { 'w-full': block }]">
                <div class="flex justify-center items-center mx-1">
                    <img class="flag" :src="$i18n.localeProperties.flag" />
                    <span class="h3">{{ $t(`languages.${$i18n.localeProperties.name}`) }}</span>
                </div>
                <icon :icon="top ? 'mdi:chevron-up' : 'mdi:chevron-down'" class="icon" />
            </button>
        </template>
        <div :class="['locale-choices rounded-sm my-1 text-black overflow-hidden shadow-lg whitespace-nowrap h4 large']">
            <div v-for="locale in availableLocales" :key="locale.code">
                <nuxt-link :to="switchLocalePath(locale.code)" :data-testid="`locale-choice-${locale.code}`">
                    <div class="locale-choice">
                        <img class="flag" :src="locale.flag" />
                        <span>{{ $t(`languages.${locale.name}`) }}</span>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </Menu>
</template>

<script>
    import Button from "~/components/library/Button.vue"
    import Menu from "~/components/library/Menu.vue"

    export default {
        name: "LanguageSelector",
        components: { Button, Menu },
        props: {
            buttonStyles: {
                type: String,
                default: "",
            },
            top: {
                type: Boolean,
                default: true,
            },
            block: {
                type: Boolean,
                default: true,
            },
        },
        data: () => ({
            menu: false,
        }),
        computed: {
            availableLocales() {
                return this.$i18n.locales
                    .filter(
                        // Filter out the locales that are not available for current country.
                        locale => this.$store.getters["api/currentCountry"].language_codes.includes(locale.iso),
                    )
                    .filter(
                        // Filter out the current locale.
                        locale => locale.code !== this.$i18n.locale,
                    )
            },
        },
    }
</script>

<style lang="scss" scoped>
    .locale-choice {
        height: 36px;
        padding-left: 16px;
        padding-right: 10px;
        line-height: normal;
        width: 100%;
        display: flex;
        > * {
            margin: auto 0;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }
    }
    .centralAligner {
        display: flex;
        > * {
            margin: auto 0;
        }
    }
    .flag {
        height: 14px;
        width: 21px;
        border-radius: 3px;
        margin-right: 5px;
    }
    .locale-choices {
        display: flex;
        flex-direction: column;
        background-color: var(--color-cream);
        &.large {
            padding: 10px 0;
            .otherCountry {
                padding: 0 var(--space-1);
            }
        }
    }
</style>
