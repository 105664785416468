import Vue from "vue"

const colors = {
    pink: "#ffc8ff",
    blue: "#a5e1ff",
    yellow: "#ffd76e",
    green: "#a0e6aa",
    success: "#4BAF4F",
    red: "#ff4628",
    meat: "#ffe6cc",
    white: "#fffff0",
    black: "#2e2e2e",
    cream: "#FFFFEB",
    turquoise: "#23c9c3",
    "background-very-light-orange": "#ffe6cc",
}
Vue.prototype.$colors = colors
