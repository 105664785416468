const LEADSOURCE_STORAGE_KEY = "leadsource"
const LEADSOURCE_COOKIE_NAME = "leadsource"

export function getLeadSourceString(source, medium, campaign, content, term) {
    return `${source},${medium},${campaign},${content},${term}`
}

export function getCookie(cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const allCookies = decodedCookie.split(";")
    for (let i = 0; i < allCookies.length; i++) {
        const c = allCookies[i].trim()
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return null
}

export function getLeadSource(includeChanged = false) {
    // See if there's a cached leadsource, return if there is
    const sessionLeadsource = window.sessionStorage.getItem(LEADSOURCE_STORAGE_KEY) || ""

    // Get the leadsource via the cookie (used by the landing page)
    let leadsource = getCookie(LEADSOURCE_COOKIE_NAME)

    if (!leadsource) {
        // Get leadsource via utm tags
        const paymentProviderList = ["paypal", "mollie", "bancontact", "giropay", "girogate", "knab", "ideal", "ing", "bunq", "kbc", "belfius", "axa", "sofort", "belgium-3ds", "cm-cic", "wlp-acs"]
        const params = new URLSearchParams(document.location.search)
        if (params.get("utm_source")) {
            leadsource = getLeadSourceString(params.get("utm_source"), params.get("utm_medium") || "", params.get("utm_campaign") || "", params.get("utm_content") || "", params.get("utm_term") || "")
        } else if (document.referrer === "" || document.referrer.match(/justrussel.(com|nl|de|fr|at|es|it|ch|lu|dk){1}/g)?.length > 0 || document.referrer.match(/localhost/g)?.length > 0 || document.referrer.match(/just-russel/g)?.length > 0) {
            leadsource = getLeadSourceString("direct", "direct", "direct", "", "")
        } else if (paymentProviderList.some(providerName => document.referrer.includes(providerName))) {
            leadsource = getLeadSourceString("payment_provider", "", "", "", "")
        } else {
            leadsource = getLeadSourceString(document.referrer, "organic", "organic", "", "")
        }
    }

    const changed = sessionLeadsource !== leadsource
    if (changed) {
        window.sessionStorage.setItem(LEADSOURCE_STORAGE_KEY, leadsource)
    }

    // TODO: update all usages to receive object, then remove includeChanged
    if (includeChanged) {
        return { leadsource, changed }
    }
    return leadsource
}
