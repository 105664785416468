<template>
    <span v-if="!loading" v-html="$t('Ordered before {today}, delivered {tomorrow}', { today, tomorrow: bold ? `<b>${tomorrow}</b>` : tomorrow })"></span>
</template>

<script>
    import Holidays from "date-holidays"

    export default {
        name: "DeliveryTimeText",
        props: {
            bold: Boolean,
        },
        data() {
            return {
                holidays: null,
                loading: true,
            }
        },
        mounted() {
            this.loading = true
            this.$store.dispatch("api/countries/get")
            this.holidays = new Holidays()
            this.holidays.init(this.$localization.currentCountry.short_name)
            this.loading = false
        },
        computed: {
            today() {
                const productionDate = this.getProductionDate()
                return productionDate.format("dddd 12:00")
            },
            tomorrow() {
                const productionDate = this.getProductionDate()

                // Add shipping days
                const deliveryDate = productionDate.clone().add(this.$store.getters["api/currentCountry"].shipping_days, "d")

                // Add an extra day for each holiday or sunday between production and delivery
                for (let i = 0; i < this.$store.getters["api/currentCountry"].shipping_days; i++) {
                    const tempDate = productionDate.clone().add(i, "d")
                    const isPublicHoliday = this.holidays.isHoliday(tempDate.toDate()) ? this.holidays.isHoliday(tempDate.toDate())[0].type === "public" : false
                    const isNonDeliveryDayTemp = this.$store.getters["api/currentCountry"].short_name === "FR" ? tempDate.day() === 0 || tempDate.day() > 5 : tempDate.day() === 0

                    if (isNonDeliveryDayTemp || isPublicHoliday) {
                        deliveryDate.add(1, "d")
                    }
                }

                const isPublicHolidayDelivery = this.holidays.isHoliday(deliveryDate.toDate()) ? this.holidays.isHoliday(deliveryDate.toDate())[0].type === "public" : false
                const isNonDeliveryDay = this.$store.getters["api/currentCountry"].short_name === "FR" ? deliveryDate.day() === 0 || deliveryDate.day() > 5 : deliveryDate.day() === 0

                // If delivery is on sunday (or saturday in FR) or holiday, will be shipped the next available day
                while (isNonDeliveryDay || isPublicHolidayDelivery) {
                    // If sunday (or saturday in FR), will be shipped monday
                    // If holiday, will be produced tomorrow
                    deliveryDate.add(1, "d")
                }

                return deliveryDate.toDate() === this.$moment().add(1, "d").toDate() ? this.$t("tomorrow") : deliveryDate.format("dddd")
            },
        },
        methods: {
            getProductionDate() {
                const productionDate = this.$moment(new Date())

                // If after 12, will be produced tomorrow
                if (productionDate.hours() >= 12) productionDate.add(1, "d")

                const isPublicHoliday = this.holidays.isHoliday(productionDate.toDate()) ? this.holidays.isHoliday(productionDate.toDate())[0].type === "public" : false

                while (productionDate.day() > 5 || productionDate.day() === 0 || isPublicHoliday) {
                    // If saturday or sunday, will be produced monday
                    // If holiday, will be produced tomorrow
                    productionDate.add(1, "d")
                }
                return productionDate
            },
        },
    }
</script>
