import { cookieExistsRegex } from "~/helpers/CookieHelper"

export default function ({ $gtm, $config, $helpers }) {
    const TradeTrackerEvents = {}

    TradeTrackerEvents.lead = customerId => {
        // Disabled
        return

        if (cookieExistsRegex(/^TT2_/)) {
            if (window.localStorage.getItem(`tt-lead_${customerId}`) === "fired") {
                return
            }
            window.localStorage.setItem(`tt-lead_${customerId}`, "fired")
            $gtm.push({
                event: "TTLeadConversion",
                env: $config.env,
                tt_transaction_id: customerId,
            })
        }
    }

    TradeTrackerEvents.sale = (customerId, delivery) => {
        if (cookieExistsRegex(/^TT2_/)) {
            if (window.localStorage.getItem(`tt-sale_${customerId}`) === "fired") {
                return
            }
            window.localStorage.setItem(`tt-sale_${customerId}`, "fired")
            $gtm.push({
                event: "TTSalesConversion",
                env: $config.env,
                tt_transaction_id: $helpers.extractId(delivery.url),
                tt_order_amount: delivery.price_excl_vat / 100,
                tt_currency: "EUR",
            })
        }
    }

    return {
        events: TradeTrackerEvents,
    }
}
