<template>
    <div class="flex justify-center flex-wrap gap-1 mx-auto">
        <img v-if="methods.includes('bancontact')" loading="lazy" class="footerImg" :src="'/shop/Bancontact.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('bancontact')" loading="lazy" class="footerImg" :src="'/shop/ING.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('bancontact')" loading="lazy" class="footerImg" :src="'/shop/KBC.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('creditcard')" loading="lazy" class="footerImg" :src="'/shop/Mastercard.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('creditcard')" loading="lazy" class="footerImg" :src="'/shop/Visa.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('ideal')" loading="lazy" class="footerImg" :src="'/shop/Ideal.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('applepay')" loading="lazy" class="footerImg" :src="'/shop/ApplePay.webp'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('paypal')" loading="lazy" class="otherFooterImg" :src="'/shop/Paypal.svg'" alt="" data-ll-status="loaded" />
        <img v-if="methods.includes('giropay')" loading="lazy" class="otherFooterImg" :src="'/shop/Giropay.svg'" alt="" data-ll-status="loaded" />
    </div>
</template>

<script>
    export default {
        name: "Footer",
        computed: {
            methods() {
                const methods = {
                    BE: ["bancontact", "creditcard", "applepay", "ideal"],
                    NL: ["ideal", "creditcard", "applepay", "bancontact"],
                    FR: ["creditcard", "applepay", "paypal"],
                    DE: ["paypal", "creditcard", "sofort", "giropay", "applepay"],
                }
                return methods[this.$localization.currentCountry?.short_name] || []
            },
        },
    }
</script>

<style lang="scss" scoped>
    .footerImg {
        width: auto;
        height: 60px;
        max-width: 100%;
    }

    .otherFooterImg {
        width: auto;
        height: 60px;
        padding: 10px 8px;
        border: 1px solid #ddd;
        border-radius: 10px;
    }
</style>
