<template>
    <div class="banner">
        <div class="p-0 flex justify-center">
            <div v-if="price" class="hidden flex my-auto mr-4">
                <!-- <icon icon="mdi:truck-fast" class="mr-2"/> -->
                <img src="/shop/Gratis-verzending.png" alt="Huis" class="mr-2 icon24" />
                <span class="flex flex-col justify-center">
                    <div class="maxContent" v-html="$t('<b>Free shipping</b> from {value}', { value: price })"></div>
                </span>
            </div>
            <div class="flex my-auto">
                <!-- <icon icon="mdi:home" class="mr-2"/> -->
                <img src="/shop/huis.png" alt="Huis" class="mr-2 icon24" />
                <div class="flex flex-col justify-center">
                    <div class="maxContent">
                        <DeliveryTimeText bold />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ShopCartMixin from "~/components/mixins/ShopCartMixin"
    import DeliveryTimeText from "~/components/features/eshop/DeliveryTimeText.vue"

    export default {
        name: "HeaderBanner",
        components: { DeliveryTimeText },
        mixins: [ShopCartMixin],
        props: {},
        computed: {
            price() {
                return this.freeDeliveryPrice && this.$helpers.format.currency(this.freeDeliveryPrice)
            },
        },
        methods: {},
        mounted() {},
        data() {
            return {}
        },
    }
</script>

<style scoped lang="scss">
    .banner {
        padding: 10px 0;
        background-color: var(--color-meat);
        text-align: center;
        font-size: 14px;
    }
    .icon24 {
        height: 18px;
        width: auto;
    }
    .maxContent {
        width: max-content;
    }
</style>
