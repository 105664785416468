export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"de","iso":"de","file":"de.ts","name":"german","flag":"/flags/de.png"},{"code":"en","iso":"en","file":"en.ts","name":"english","flag":"/flags/en.png"},{"code":"nl","iso":"nl","file":"nl.ts","name":"dutch","flag":"/flags/nl.png"},{"code":"nl-be","iso":"nl-BE","file":"nl.ts","name":"dutch","flag":"/flags/be.png"},{"code":"fr","iso":"fr","file":"fr.ts","name":"french","flag":"/flags/fr.png"},{"code":"fr-be","iso":"fr-BE","file":"fr.ts","name":"french","flag":"/flags/be.png"},{"code":"be-fr","iso":"BE-FR","file":"fr.ts","name":"french","flag":"/flags/be.png"},{"code":"be-nl","iso":"BE-NL","file":"nl.ts","name":"dutch","flag":"/flags/be.png"}],
  defaultLocale: "nl-be",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/home/runner/work/frontend/frontend/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"de","iso":"de","file":"de.ts","name":"german","flag":"/flags/de.png"},{"code":"en","iso":"en","file":"en.ts","name":"english","flag":"/flags/en.png"},{"code":"nl","iso":"nl","file":"nl.ts","name":"dutch","flag":"/flags/nl.png"},{"code":"nl-be","iso":"nl-BE","file":"nl.ts","name":"dutch","flag":"/flags/be.png"},{"code":"fr","iso":"fr","file":"fr.ts","name":"french","flag":"/flags/fr.png"},{"code":"fr-be","iso":"fr-BE","file":"fr.ts","name":"french","flag":"/flags/be.png"},{"code":"be-fr","iso":"BE-FR","file":"fr.ts","name":"french","flag":"/flags/be.png"},{"code":"be-nl","iso":"BE-NL","file":"nl.ts","name":"dutch","flag":"/flags/be.png"}],
  localeCodes: ["de","en","nl","nl-be","fr","fr-be","be-fr","be-nl"],
}

export const localeMessages = {
  'de.ts': () => import('../../lang/de.ts' /* webpackChunkName: "lang-de.ts" */),
  'en.ts': () => import('../../lang/en.ts' /* webpackChunkName: "lang-en.ts" */),
  'nl.ts': () => import('../../lang/nl.ts' /* webpackChunkName: "lang-nl.ts" */),
  'fr.ts': () => import('../../lang/fr.ts' /* webpackChunkName: "lang-fr.ts" */),
}
