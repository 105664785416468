<template>
    <div ref="menuContainer" class="relative">
        <slot name="activator" role="menu"></slot>
        <Animation
            fadeIn
            v-model="value"
            :class="[
                'absolute z-30',
                topHeight ? '' : top ? 'bottom-full' : 'top-full',
                {
                    'left-1/2 -translate-x-1/2': !leftFull && !rightFull && !left0 && !right0,
                    'left-full': leftFull,
                    'right-full': rightFull,
                    'left-0': left0,
                    'right-0': right0,
                },
            ]"
            :style="topHeight ? `top: ${topHeight}` : ''"
        >
            <slot></slot>
        </Animation>
    </div>
</template>

<script>
    import Animation from "./Animation.vue"

    export default {
        components: { Animation },
        props: {
            value: Boolean,
            leftFull: Boolean,
            rightFull: Boolean,
            left0: Boolean,
            right0: Boolean,
            top: Boolean,
            topHeight: String,
        },
        mounted() {
            window.addEventListener("click", this.close)
        },
        beforeDestroy() {
            window.removeEventListener("click", this.close)
        },
        methods: {
            close(e) {
                if (this.value && !this.$refs.menuContainer.contains(e.target)) this.$emit("input", false)
            },
        },
    }
</script>

<style lang="scss" scoped></style>
