import _ from "lodash"
// @ts-ignore
import { v4 as uuidv4 } from "uuid"

const getDefaultState = () => {
    // get cart from localstorage json
    let cartJSON = JSON.parse(window.localStorage.getItem("cart") || "{}")

    let id = null
    let items = {}
    if (Object.keys(cartJSON).includes("id") && Object.keys(cartJSON).includes("items")) {
        id = cartJSON.id
        items = cartJSON.items
    } else {
        // Legacy cart
        items = cartJSON

        // Convert legacy cart to new cart
        id = uuidv4()
        const newCart = {
            id,
            items,
        }
        window.localStorage.setItem("cart", JSON.stringify(newCart))
    }

    return {
        id: id,
        currentLocale: null,
        productCategoriesCacheLocale: null,
        items: items,
        coupon: "",
    }
}

export const state = () => getDefaultState()

export const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setItems(state, payload) {
        state.items = payload
        window.localStorage.setItem(
            "cart",
            JSON.stringify({
                id: state.id || uuidv4(),
                items: state.items,
            }),
        )
    },
    setCoupon(state, payload) {
        state.coupon = payload
    },
    setDeliveryAddress(state, payload) {
        state.delivery_address = payload
    },
    setBillingAddress(state, payload) {
        state.billing_address = payload
    },
    setCurrentLocale(state, payload) {
        state.currentLocale = payload
    },
    setProductCategoriesCacheLocale(state, payload) {
        state.productCategoriesCacheLocale = payload
    },
}
