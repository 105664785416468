export const capitalizeFirstLetter = string => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeEachWord = string => {
    string = string.toLowerCase()
    string = string
        .split(" ")
        .map(x => (x ? x[0].toUpperCase() + x.slice(1) : x))
        .join(" ")
    string = string
        .split("-")
        .map(x => (x ? x[0].toUpperCase() + x.slice(1) : x))
        .join("-")
    return string
}

export const capitalizeFirstWordAndInferTheOther = string => {
    string = string.trim()
    if (string.length) string = string[0].toUpperCase() + string.slice(1)
    string = string
        .split(" ")
        .map(x =>
            x
                .split("-")
                .map(x => (!x ? "" : x[0] + x.slice(1).toLowerCase()))
                .join("-"),
        )
        .join(" ")
    return string
}

export const titleCase = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const currency =
    localization =>
    (cents, hideCurrency = false, hideCents = false) => {
        let prefix = ""
        if (!hideCurrency && cents < 0 && localization.locale !== "fr") {
            prefix = "- "
            cents = -cents
        }
        let price = `${(cents.toFixed(2) / 100).toFixed(hideCents ? 0 : 2).replace(".", ",")}`
        if (hideCurrency) return price
        return localization.locale === "fr" ? `${prefix}${price} €` : `${prefix}€ ${price}`
    }

export const formatCurrency = localization => value => {
    value = value.replace("€", "").replace(" ", "")
    if (localization.locale === "fr") return value + " €"
    return "€" + value
}

export function getSummationString(list) {
    let result = ""
    list.forEach((item, index) => {
        if (index < list.length - 2) {
            result += `${item}, `
        } else if (index === list.length - 2) {
            result += `${item} & `
        } else {
            result += item
        }
    })
    return result
}
