import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _291d1349 = () => interopDefault(import('../pages/after-checkout-redirect.vue' /* webpackChunkName: "pages/after-checkout-redirect" */))
const _7c0c6aab = () => interopDefault(import('../pages/after-mandate-redirect.vue' /* webpackChunkName: "pages/after-mandate-redirect" */))
const _9d7579ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _004fcc92 = () => interopDefault(import('../pages/change-pet-profile.vue' /* webpackChunkName: "pages/change-pet-profile" */))
const _2cb9f722 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _61eb4eb4 = () => interopDefault(import('../pages/checkout-dashboard.vue' /* webpackChunkName: "pages/checkout-dashboard" */))
const _14d6ec4a = () => interopDefault(import('../pages/checkout-price.vue' /* webpackChunkName: "pages/checkout-price" */))
const _5fa5088a = () => interopDefault(import('../pages/checkout-profile.vue' /* webpackChunkName: "pages/checkout-profile" */))
const _58baa138 = () => interopDefault(import('../pages/checkout-upsells.vue' /* webpackChunkName: "pages/checkout-upsells" */))
const _21c9ce8e = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4eb14e7b = () => interopDefault(import('../pages/ehealth/index.vue' /* webpackChunkName: "pages/ehealth/index" */))
const _dcd1466c = () => interopDefault(import('../pages/firebase-webhook.vue' /* webpackChunkName: "pages/firebase-webhook" */))
const _988b0008 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _17a76b54 = () => interopDefault(import('../pages/insurance/index.vue' /* webpackChunkName: "pages/insurance/index" */))
const _7dfb1000 = () => interopDefault(import('../pages/pet-overview.vue' /* webpackChunkName: "pages/pet-overview" */))
const _b100edd8 = () => interopDefault(import('../pages/protectedPage.vue' /* webpackChunkName: "pages/protectedPage" */))
const _5b3a0068 = () => interopDefault(import('../pages/questionnaires/index.vue' /* webpackChunkName: "pages/questionnaires/index" */))
const _73287152 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _1a73747b = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _2f09caf1 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _0b0f73db = () => interopDefault(import('../pages/unprotectedPage.vue' /* webpackChunkName: "pages/unprotectedPage" */))
const _276c3d7a = () => interopDefault(import('../pages/checkout/oops.vue' /* webpackChunkName: "pages/checkout/oops" */))
const _ed3e805a = () => interopDefault(import('../pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _3aca8d55 = () => interopDefault(import('../pages/checkout/thankyou.vue' /* webpackChunkName: "pages/checkout/thankyou" */))
const _4b273f60 = () => interopDefault(import('../pages/dashboard/history.vue' /* webpackChunkName: "pages/dashboard/history" */))
const _7ba45360 = () => interopDefault(import('../pages/dashboard/mealplan.vue' /* webpackChunkName: "pages/dashboard/mealplan" */))
const _3921ab28 = () => interopDefault(import('../pages/dashboard/pets.vue' /* webpackChunkName: "pages/dashboard/pets" */))
const _5fef1a36 = () => interopDefault(import('../pages/dashboard/profile.vue' /* webpackChunkName: "pages/dashboard/profile" */))
const _18c0b1ca = () => interopDefault(import('../pages/dashboard/share.vue' /* webpackChunkName: "pages/dashboard/share" */))
const _06c561ea = () => interopDefault(import('../pages/dashboard/shop.vue' /* webpackChunkName: "pages/dashboard/shop" */))
const _387acc0f = () => interopDefault(import('../pages/dashboard/subscription-settings.vue' /* webpackChunkName: "pages/dashboard/subscription-settings" */))
const _fec58b26 = () => interopDefault(import('../pages/dashboard/wallet.vue' /* webpackChunkName: "pages/dashboard/wallet" */))
const _48aed714 = () => interopDefault(import('../pages/ehealth/activity.vue' /* webpackChunkName: "pages/ehealth/activity" */))
const _63b253e4 = () => interopDefault(import('../pages/ehealth/change-complaints.vue' /* webpackChunkName: "pages/ehealth/change-complaints" */))
const _d43c8bb6 = () => interopDefault(import('../pages/ehealth/change-condition.vue' /* webpackChunkName: "pages/ehealth/change-condition" */))
const _671bb9a9 = () => interopDefault(import('../pages/ehealth/change-pet.vue' /* webpackChunkName: "pages/ehealth/change-pet" */))
const _7e79c760 = () => interopDefault(import('../pages/ehealth/discover.vue' /* webpackChunkName: "pages/ehealth/discover" */))
const _73ce180e = () => interopDefault(import('../pages/ehealth/passport.vue' /* webpackChunkName: "pages/ehealth/passport" */))
const _04ba31c4 = () => interopDefault(import('../pages/ehealth/planning.vue' /* webpackChunkName: "pages/ehealth/planning" */))
const _d953160c = () => interopDefault(import('../pages/ehealth/symptom-checker.vue' /* webpackChunkName: "pages/ehealth/symptom-checker" */))
const _a9615230 = () => interopDefault(import('../pages/insurance/calculate.vue' /* webpackChunkName: "pages/insurance/calculate" */))
const _018f53e3 = () => interopDefault(import('../pages/insurance/thankyou.vue' /* webpackChunkName: "pages/insurance/thankyou" */))
const _5e4bb560 = () => interopDefault(import('../pages/questionnaires/cat.vue' /* webpackChunkName: "pages/questionnaires/cat" */))
const _1ced07b6 = () => interopDefault(import('../pages/questionnaires/dog.vue' /* webpackChunkName: "pages/questionnaires/dog" */))
const _81cb66ba = () => interopDefault(import('../pages/questionnaires/profile.vue' /* webpackChunkName: "pages/questionnaires/profile" */))
const _30cb2d18 = () => interopDefault(import('../pages/shop/address.vue' /* webpackChunkName: "pages/shop/address" */))
const _1e5dd1d0 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _27381ca4 = () => interopDefault(import('../pages/shop/delivery.vue' /* webpackChunkName: "pages/shop/delivery" */))
const _ace73470 = () => interopDefault(import('../pages/shop/signup.vue' /* webpackChunkName: "pages/shop/signup" */))
const _6d27a525 = () => interopDefault(import('../pages/shop/thankyou.vue' /* webpackChunkName: "pages/shop/thankyou" */))
const _4ad4f88f = () => interopDefault(import('../pages/dashboard/manage/quantities.vue' /* webpackChunkName: "pages/dashboard/manage/quantities" */))
const _4288d3af = () => interopDefault(import('../pages/reset-password/_code.vue' /* webpackChunkName: "pages/reset-password/_code" */))
const _48f298ea = () => interopDefault(import('../pages/shop/_productName.vue' /* webpackChunkName: "pages/shop/_productName" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___nl-be___default"
  }, {
    path: "/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___nl-be___default"
  }, {
    path: "/be-fr",
    component: _9d7579ee,
    name: "index___be-fr"
  }, {
    path: "/be-nl",
    component: _9d7579ee,
    name: "index___be-nl"
  }, {
    path: "/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___nl-be___default"
  }, {
    path: "/checkout",
    component: _2cb9f722,
    name: "checkout___nl-be___default"
  }, {
    path: "/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___nl-be___default"
  }, {
    path: "/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___nl-be___default"
  }, {
    path: "/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___nl-be___default"
  }, {
    path: "/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___nl-be___default"
  }, {
    path: "/dashboard",
    component: _21c9ce8e,
    name: "dashboard___nl-be___default"
  }, {
    path: "/de",
    component: _9d7579ee,
    name: "index___de"
  }, {
    path: "/ehealth",
    component: _4eb14e7b,
    name: "ehealth___nl-be___default"
  }, {
    path: "/en",
    component: _9d7579ee,
    name: "index___en"
  }, {
    path: "/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___nl-be___default"
  }, {
    path: "/forgot-password",
    component: _988b0008,
    name: "forgot-password___nl-be___default"
  }, {
    path: "/fr",
    component: _9d7579ee,
    name: "index___fr"
  }, {
    path: "/fr-be",
    component: _9d7579ee,
    name: "index___fr-be"
  }, {
    path: "/insurance",
    component: _17a76b54,
    name: "insurance___nl-be___default"
  }, {
    path: "/nl",
    component: _9d7579ee,
    name: "index___nl"
  }, {
    path: "/nl-be",
    component: _9d7579ee,
    name: "index___nl-be"
  }, {
    path: "/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___nl-be___default"
  }, {
    path: "/protectedPage",
    component: _b100edd8,
    name: "protectedPage___nl-be___default"
  }, {
    path: "/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___nl-be___default"
  }, {
    path: "/shop",
    component: _73287152,
    name: "shop___nl-be___default"
  }, {
    path: "/signin",
    component: _1a73747b,
    name: "signin___nl-be___default"
  }, {
    path: "/signup",
    component: _2f09caf1,
    name: "signup___nl-be___default"
  }, {
    path: "/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___nl-be___default"
  }, {
    path: "/be-fr/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___be-fr"
  }, {
    path: "/be-fr/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___be-fr"
  }, {
    path: "/be-fr/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___be-fr"
  }, {
    path: "/be-fr/checkout",
    component: _2cb9f722,
    name: "checkout___be-fr"
  }, {
    path: "/be-fr/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___be-fr"
  }, {
    path: "/be-fr/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___be-fr"
  }, {
    path: "/be-fr/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___be-fr"
  }, {
    path: "/be-fr/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___be-fr"
  }, {
    path: "/be-fr/dashboard",
    component: _21c9ce8e,
    name: "dashboard___be-fr"
  }, {
    path: "/be-fr/ehealth",
    component: _4eb14e7b,
    name: "ehealth___be-fr"
  }, {
    path: "/be-fr/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___be-fr"
  }, {
    path: "/be-fr/forgot-password",
    component: _988b0008,
    name: "forgot-password___be-fr"
  }, {
    path: "/be-fr/insurance",
    component: _17a76b54,
    name: "insurance___be-fr"
  }, {
    path: "/be-fr/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___be-fr"
  }, {
    path: "/be-fr/protectedPage",
    component: _b100edd8,
    name: "protectedPage___be-fr"
  }, {
    path: "/be-fr/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___be-fr"
  }, {
    path: "/be-fr/shop",
    component: _73287152,
    name: "shop___be-fr"
  }, {
    path: "/be-fr/signin",
    component: _1a73747b,
    name: "signin___be-fr"
  }, {
    path: "/be-fr/signup",
    component: _2f09caf1,
    name: "signup___be-fr"
  }, {
    path: "/be-fr/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___be-fr"
  }, {
    path: "/be-nl/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___be-nl"
  }, {
    path: "/be-nl/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___be-nl"
  }, {
    path: "/be-nl/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___be-nl"
  }, {
    path: "/be-nl/checkout",
    component: _2cb9f722,
    name: "checkout___be-nl"
  }, {
    path: "/be-nl/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___be-nl"
  }, {
    path: "/be-nl/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___be-nl"
  }, {
    path: "/be-nl/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___be-nl"
  }, {
    path: "/be-nl/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___be-nl"
  }, {
    path: "/be-nl/dashboard",
    component: _21c9ce8e,
    name: "dashboard___be-nl"
  }, {
    path: "/be-nl/ehealth",
    component: _4eb14e7b,
    name: "ehealth___be-nl"
  }, {
    path: "/be-nl/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___be-nl"
  }, {
    path: "/be-nl/forgot-password",
    component: _988b0008,
    name: "forgot-password___be-nl"
  }, {
    path: "/be-nl/insurance",
    component: _17a76b54,
    name: "insurance___be-nl"
  }, {
    path: "/be-nl/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___be-nl"
  }, {
    path: "/be-nl/protectedPage",
    component: _b100edd8,
    name: "protectedPage___be-nl"
  }, {
    path: "/be-nl/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___be-nl"
  }, {
    path: "/be-nl/shop",
    component: _73287152,
    name: "shop___be-nl"
  }, {
    path: "/be-nl/signin",
    component: _1a73747b,
    name: "signin___be-nl"
  }, {
    path: "/be-nl/signup",
    component: _2f09caf1,
    name: "signup___be-nl"
  }, {
    path: "/be-nl/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___be-nl"
  }, {
    path: "/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___nl-be___default"
  }, {
    path: "/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___nl-be___default"
  }, {
    path: "/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___nl-be___default"
  }, {
    path: "/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___nl-be___default"
  }, {
    path: "/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___nl-be___default"
  }, {
    path: "/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___nl-be___default"
  }, {
    path: "/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___nl-be___default"
  }, {
    path: "/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___nl-be___default"
  }, {
    path: "/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___nl-be___default"
  }, {
    path: "/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___nl-be___default"
  }, {
    path: "/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___nl-be___default"
  }, {
    path: "/de/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___de"
  }, {
    path: "/de/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___de"
  }, {
    path: "/de/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___de"
  }, {
    path: "/de/checkout",
    component: _2cb9f722,
    name: "checkout___de"
  }, {
    path: "/de/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___de"
  }, {
    path: "/de/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___de"
  }, {
    path: "/de/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___de"
  }, {
    path: "/de/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___de"
  }, {
    path: "/de/dashboard",
    component: _21c9ce8e,
    name: "dashboard___de"
  }, {
    path: "/de/ehealth",
    component: _4eb14e7b,
    name: "ehealth___de"
  }, {
    path: "/de/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___de"
  }, {
    path: "/de/forgot-password",
    component: _988b0008,
    name: "forgot-password___de"
  }, {
    path: "/de/insurance",
    component: _17a76b54,
    name: "insurance___de"
  }, {
    path: "/de/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___de"
  }, {
    path: "/de/protectedPage",
    component: _b100edd8,
    name: "protectedPage___de"
  }, {
    path: "/de/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___de"
  }, {
    path: "/de/shop",
    component: _73287152,
    name: "shop___de"
  }, {
    path: "/de/signin",
    component: _1a73747b,
    name: "signin___de"
  }, {
    path: "/de/signup",
    component: _2f09caf1,
    name: "signup___de"
  }, {
    path: "/de/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___de"
  }, {
    path: "/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___nl-be___default"
  }, {
    path: "/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___nl-be___default"
  }, {
    path: "/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___nl-be___default"
  }, {
    path: "/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___nl-be___default"
  }, {
    path: "/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___nl-be___default"
  }, {
    path: "/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___nl-be___default"
  }, {
    path: "/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___nl-be___default"
  }, {
    path: "/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___nl-be___default"
  }, {
    path: "/en/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___en"
  }, {
    path: "/en/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___en"
  }, {
    path: "/en/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___en"
  }, {
    path: "/en/checkout",
    component: _2cb9f722,
    name: "checkout___en"
  }, {
    path: "/en/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___en"
  }, {
    path: "/en/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___en"
  }, {
    path: "/en/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___en"
  }, {
    path: "/en/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___en"
  }, {
    path: "/en/dashboard",
    component: _21c9ce8e,
    name: "dashboard___en"
  }, {
    path: "/en/ehealth",
    component: _4eb14e7b,
    name: "ehealth___en"
  }, {
    path: "/en/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___en"
  }, {
    path: "/en/forgot-password",
    component: _988b0008,
    name: "forgot-password___en"
  }, {
    path: "/en/insurance",
    component: _17a76b54,
    name: "insurance___en"
  }, {
    path: "/en/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___en"
  }, {
    path: "/en/protectedPage",
    component: _b100edd8,
    name: "protectedPage___en"
  }, {
    path: "/en/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___en"
  }, {
    path: "/en/shop",
    component: _73287152,
    name: "shop___en"
  }, {
    path: "/en/signin",
    component: _1a73747b,
    name: "signin___en"
  }, {
    path: "/en/signup",
    component: _2f09caf1,
    name: "signup___en"
  }, {
    path: "/en/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___en"
  }, {
    path: "/fr-be/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___fr-be"
  }, {
    path: "/fr-be/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___fr-be"
  }, {
    path: "/fr-be/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___fr-be"
  }, {
    path: "/fr-be/checkout",
    component: _2cb9f722,
    name: "checkout___fr-be"
  }, {
    path: "/fr-be/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___fr-be"
  }, {
    path: "/fr-be/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___fr-be"
  }, {
    path: "/fr-be/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___fr-be"
  }, {
    path: "/fr-be/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___fr-be"
  }, {
    path: "/fr-be/dashboard",
    component: _21c9ce8e,
    name: "dashboard___fr-be"
  }, {
    path: "/fr-be/ehealth",
    component: _4eb14e7b,
    name: "ehealth___fr-be"
  }, {
    path: "/fr-be/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___fr-be"
  }, {
    path: "/fr-be/forgot-password",
    component: _988b0008,
    name: "forgot-password___fr-be"
  }, {
    path: "/fr-be/insurance",
    component: _17a76b54,
    name: "insurance___fr-be"
  }, {
    path: "/fr-be/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___fr-be"
  }, {
    path: "/fr-be/protectedPage",
    component: _b100edd8,
    name: "protectedPage___fr-be"
  }, {
    path: "/fr-be/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___fr-be"
  }, {
    path: "/fr-be/shop",
    component: _73287152,
    name: "shop___fr-be"
  }, {
    path: "/fr-be/signin",
    component: _1a73747b,
    name: "signin___fr-be"
  }, {
    path: "/fr-be/signup",
    component: _2f09caf1,
    name: "signup___fr-be"
  }, {
    path: "/fr-be/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___fr-be"
  }, {
    path: "/fr/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___fr"
  }, {
    path: "/fr/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___fr"
  }, {
    path: "/fr/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___fr"
  }, {
    path: "/fr/checkout",
    component: _2cb9f722,
    name: "checkout___fr"
  }, {
    path: "/fr/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___fr"
  }, {
    path: "/fr/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___fr"
  }, {
    path: "/fr/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___fr"
  }, {
    path: "/fr/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___fr"
  }, {
    path: "/fr/dashboard",
    component: _21c9ce8e,
    name: "dashboard___fr"
  }, {
    path: "/fr/ehealth",
    component: _4eb14e7b,
    name: "ehealth___fr"
  }, {
    path: "/fr/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___fr"
  }, {
    path: "/fr/forgot-password",
    component: _988b0008,
    name: "forgot-password___fr"
  }, {
    path: "/fr/insurance",
    component: _17a76b54,
    name: "insurance___fr"
  }, {
    path: "/fr/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___fr"
  }, {
    path: "/fr/protectedPage",
    component: _b100edd8,
    name: "protectedPage___fr"
  }, {
    path: "/fr/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___fr"
  }, {
    path: "/fr/shop",
    component: _73287152,
    name: "shop___fr"
  }, {
    path: "/fr/signin",
    component: _1a73747b,
    name: "signin___fr"
  }, {
    path: "/fr/signup",
    component: _2f09caf1,
    name: "signup___fr"
  }, {
    path: "/fr/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___fr"
  }, {
    path: "/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___nl-be___default"
  }, {
    path: "/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___nl-be___default"
  }, {
    path: "/nl-be/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___nl-be"
  }, {
    path: "/nl-be/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___nl-be"
  }, {
    path: "/nl-be/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___nl-be"
  }, {
    path: "/nl-be/checkout",
    component: _2cb9f722,
    name: "checkout___nl-be"
  }, {
    path: "/nl-be/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___nl-be"
  }, {
    path: "/nl-be/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___nl-be"
  }, {
    path: "/nl-be/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___nl-be"
  }, {
    path: "/nl-be/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___nl-be"
  }, {
    path: "/nl-be/dashboard",
    component: _21c9ce8e,
    name: "dashboard___nl-be"
  }, {
    path: "/nl-be/ehealth",
    component: _4eb14e7b,
    name: "ehealth___nl-be"
  }, {
    path: "/nl-be/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___nl-be"
  }, {
    path: "/nl-be/forgot-password",
    component: _988b0008,
    name: "forgot-password___nl-be"
  }, {
    path: "/nl-be/insurance",
    component: _17a76b54,
    name: "insurance___nl-be"
  }, {
    path: "/nl-be/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___nl-be"
  }, {
    path: "/nl-be/protectedPage",
    component: _b100edd8,
    name: "protectedPage___nl-be"
  }, {
    path: "/nl-be/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___nl-be"
  }, {
    path: "/nl-be/shop",
    component: _73287152,
    name: "shop___nl-be"
  }, {
    path: "/nl-be/signin",
    component: _1a73747b,
    name: "signin___nl-be"
  }, {
    path: "/nl-be/signup",
    component: _2f09caf1,
    name: "signup___nl-be"
  }, {
    path: "/nl-be/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___nl-be"
  }, {
    path: "/nl/after-checkout-redirect",
    component: _291d1349,
    name: "after-checkout-redirect___nl"
  }, {
    path: "/nl/after-mandate-redirect",
    component: _7c0c6aab,
    name: "after-mandate-redirect___nl"
  }, {
    path: "/nl/change-pet-profile",
    component: _004fcc92,
    name: "change-pet-profile___nl"
  }, {
    path: "/nl/checkout",
    component: _2cb9f722,
    name: "checkout___nl"
  }, {
    path: "/nl/checkout-dashboard",
    component: _61eb4eb4,
    name: "checkout-dashboard___nl"
  }, {
    path: "/nl/checkout-price",
    component: _14d6ec4a,
    name: "checkout-price___nl"
  }, {
    path: "/nl/checkout-profile",
    component: _5fa5088a,
    name: "checkout-profile___nl"
  }, {
    path: "/nl/checkout-upsells",
    component: _58baa138,
    name: "checkout-upsells___nl"
  }, {
    path: "/nl/dashboard",
    component: _21c9ce8e,
    name: "dashboard___nl"
  }, {
    path: "/nl/ehealth",
    component: _4eb14e7b,
    name: "ehealth___nl"
  }, {
    path: "/nl/firebase-webhook",
    component: _dcd1466c,
    name: "firebase-webhook___nl"
  }, {
    path: "/nl/forgot-password",
    component: _988b0008,
    name: "forgot-password___nl"
  }, {
    path: "/nl/insurance",
    component: _17a76b54,
    name: "insurance___nl"
  }, {
    path: "/nl/pet-overview",
    component: _7dfb1000,
    name: "pet-overview___nl"
  }, {
    path: "/nl/protectedPage",
    component: _b100edd8,
    name: "protectedPage___nl"
  }, {
    path: "/nl/questionnaires",
    component: _5b3a0068,
    name: "questionnaires___nl"
  }, {
    path: "/nl/shop",
    component: _73287152,
    name: "shop___nl"
  }, {
    path: "/nl/signin",
    component: _1a73747b,
    name: "signin___nl"
  }, {
    path: "/nl/signup",
    component: _2f09caf1,
    name: "signup___nl"
  }, {
    path: "/nl/unprotectedPage",
    component: _0b0f73db,
    name: "unprotectedPage___nl"
  }, {
    path: "/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___nl-be___default"
  }, {
    path: "/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___nl-be___default"
  }, {
    path: "/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___nl-be___default"
  }, {
    path: "/shop/address",
    component: _30cb2d18,
    name: "shop-address___nl-be___default"
  }, {
    path: "/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___nl-be___default"
  }, {
    path: "/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___nl-be___default"
  }, {
    path: "/shop/signup",
    component: _ace73470,
    name: "shop-signup___nl-be___default"
  }, {
    path: "/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___nl-be___default"
  }, {
    path: "/be-fr/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___be-fr"
  }, {
    path: "/be-fr/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___be-fr"
  }, {
    path: "/be-fr/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___be-fr"
  }, {
    path: "/be-fr/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___be-fr"
  }, {
    path: "/be-fr/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___be-fr"
  }, {
    path: "/be-fr/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___be-fr"
  }, {
    path: "/be-fr/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___be-fr"
  }, {
    path: "/be-fr/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___be-fr"
  }, {
    path: "/be-fr/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___be-fr"
  }, {
    path: "/be-fr/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___be-fr"
  }, {
    path: "/be-fr/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___be-fr"
  }, {
    path: "/be-fr/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___be-fr"
  }, {
    path: "/be-fr/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___be-fr"
  }, {
    path: "/be-fr/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___be-fr"
  }, {
    path: "/be-fr/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___be-fr"
  }, {
    path: "/be-fr/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___be-fr"
  }, {
    path: "/be-fr/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___be-fr"
  }, {
    path: "/be-fr/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___be-fr"
  }, {
    path: "/be-fr/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___be-fr"
  }, {
    path: "/be-fr/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___be-fr"
  }, {
    path: "/be-fr/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___be-fr"
  }, {
    path: "/be-fr/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___be-fr"
  }, {
    path: "/be-fr/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___be-fr"
  }, {
    path: "/be-fr/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___be-fr"
  }, {
    path: "/be-fr/shop/address",
    component: _30cb2d18,
    name: "shop-address___be-fr"
  }, {
    path: "/be-fr/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___be-fr"
  }, {
    path: "/be-fr/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___be-fr"
  }, {
    path: "/be-fr/shop/signup",
    component: _ace73470,
    name: "shop-signup___be-fr"
  }, {
    path: "/be-fr/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___be-fr"
  }, {
    path: "/be-nl/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___be-nl"
  }, {
    path: "/be-nl/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___be-nl"
  }, {
    path: "/be-nl/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___be-nl"
  }, {
    path: "/be-nl/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___be-nl"
  }, {
    path: "/be-nl/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___be-nl"
  }, {
    path: "/be-nl/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___be-nl"
  }, {
    path: "/be-nl/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___be-nl"
  }, {
    path: "/be-nl/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___be-nl"
  }, {
    path: "/be-nl/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___be-nl"
  }, {
    path: "/be-nl/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___be-nl"
  }, {
    path: "/be-nl/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___be-nl"
  }, {
    path: "/be-nl/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___be-nl"
  }, {
    path: "/be-nl/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___be-nl"
  }, {
    path: "/be-nl/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___be-nl"
  }, {
    path: "/be-nl/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___be-nl"
  }, {
    path: "/be-nl/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___be-nl"
  }, {
    path: "/be-nl/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___be-nl"
  }, {
    path: "/be-nl/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___be-nl"
  }, {
    path: "/be-nl/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___be-nl"
  }, {
    path: "/be-nl/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___be-nl"
  }, {
    path: "/be-nl/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___be-nl"
  }, {
    path: "/be-nl/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___be-nl"
  }, {
    path: "/be-nl/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___be-nl"
  }, {
    path: "/be-nl/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___be-nl"
  }, {
    path: "/be-nl/shop/address",
    component: _30cb2d18,
    name: "shop-address___be-nl"
  }, {
    path: "/be-nl/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___be-nl"
  }, {
    path: "/be-nl/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___be-nl"
  }, {
    path: "/be-nl/shop/signup",
    component: _ace73470,
    name: "shop-signup___be-nl"
  }, {
    path: "/be-nl/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___be-nl"
  }, {
    path: "/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___nl-be___default"
  }, {
    path: "/de/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___de"
  }, {
    path: "/de/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___de"
  }, {
    path: "/de/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___de"
  }, {
    path: "/de/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___de"
  }, {
    path: "/de/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___de"
  }, {
    path: "/de/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___de"
  }, {
    path: "/de/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___de"
  }, {
    path: "/de/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___de"
  }, {
    path: "/de/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___de"
  }, {
    path: "/de/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___de"
  }, {
    path: "/de/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___de"
  }, {
    path: "/de/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___de"
  }, {
    path: "/de/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___de"
  }, {
    path: "/de/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___de"
  }, {
    path: "/de/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___de"
  }, {
    path: "/de/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___de"
  }, {
    path: "/de/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___de"
  }, {
    path: "/de/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___de"
  }, {
    path: "/de/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___de"
  }, {
    path: "/de/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___de"
  }, {
    path: "/de/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___de"
  }, {
    path: "/de/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___de"
  }, {
    path: "/de/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___de"
  }, {
    path: "/de/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___de"
  }, {
    path: "/de/shop/address",
    component: _30cb2d18,
    name: "shop-address___de"
  }, {
    path: "/de/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___de"
  }, {
    path: "/de/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___de"
  }, {
    path: "/de/shop/signup",
    component: _ace73470,
    name: "shop-signup___de"
  }, {
    path: "/de/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___de"
  }, {
    path: "/en/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___en"
  }, {
    path: "/en/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___en"
  }, {
    path: "/en/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___en"
  }, {
    path: "/en/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___en"
  }, {
    path: "/en/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___en"
  }, {
    path: "/en/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___en"
  }, {
    path: "/en/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___en"
  }, {
    path: "/en/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___en"
  }, {
    path: "/en/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___en"
  }, {
    path: "/en/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___en"
  }, {
    path: "/en/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___en"
  }, {
    path: "/en/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___en"
  }, {
    path: "/en/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___en"
  }, {
    path: "/en/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___en"
  }, {
    path: "/en/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___en"
  }, {
    path: "/en/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___en"
  }, {
    path: "/en/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___en"
  }, {
    path: "/en/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___en"
  }, {
    path: "/en/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___en"
  }, {
    path: "/en/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___en"
  }, {
    path: "/en/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___en"
  }, {
    path: "/en/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___en"
  }, {
    path: "/en/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___en"
  }, {
    path: "/en/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___en"
  }, {
    path: "/en/shop/address",
    component: _30cb2d18,
    name: "shop-address___en"
  }, {
    path: "/en/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___en"
  }, {
    path: "/en/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___en"
  }, {
    path: "/en/shop/signup",
    component: _ace73470,
    name: "shop-signup___en"
  }, {
    path: "/en/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___en"
  }, {
    path: "/fr-be/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___fr-be"
  }, {
    path: "/fr-be/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___fr-be"
  }, {
    path: "/fr-be/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___fr-be"
  }, {
    path: "/fr-be/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___fr-be"
  }, {
    path: "/fr-be/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___fr-be"
  }, {
    path: "/fr-be/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___fr-be"
  }, {
    path: "/fr-be/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___fr-be"
  }, {
    path: "/fr-be/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___fr-be"
  }, {
    path: "/fr-be/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___fr-be"
  }, {
    path: "/fr-be/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___fr-be"
  }, {
    path: "/fr-be/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___fr-be"
  }, {
    path: "/fr-be/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___fr-be"
  }, {
    path: "/fr-be/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___fr-be"
  }, {
    path: "/fr-be/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___fr-be"
  }, {
    path: "/fr-be/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___fr-be"
  }, {
    path: "/fr-be/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___fr-be"
  }, {
    path: "/fr-be/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___fr-be"
  }, {
    path: "/fr-be/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___fr-be"
  }, {
    path: "/fr-be/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___fr-be"
  }, {
    path: "/fr-be/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___fr-be"
  }, {
    path: "/fr-be/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___fr-be"
  }, {
    path: "/fr-be/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___fr-be"
  }, {
    path: "/fr-be/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___fr-be"
  }, {
    path: "/fr-be/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___fr-be"
  }, {
    path: "/fr-be/shop/address",
    component: _30cb2d18,
    name: "shop-address___fr-be"
  }, {
    path: "/fr-be/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___fr-be"
  }, {
    path: "/fr-be/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___fr-be"
  }, {
    path: "/fr-be/shop/signup",
    component: _ace73470,
    name: "shop-signup___fr-be"
  }, {
    path: "/fr-be/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___fr-be"
  }, {
    path: "/fr/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___fr"
  }, {
    path: "/fr/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___fr"
  }, {
    path: "/fr/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___fr"
  }, {
    path: "/fr/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___fr"
  }, {
    path: "/fr/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___fr"
  }, {
    path: "/fr/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___fr"
  }, {
    path: "/fr/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___fr"
  }, {
    path: "/fr/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___fr"
  }, {
    path: "/fr/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___fr"
  }, {
    path: "/fr/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___fr"
  }, {
    path: "/fr/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___fr"
  }, {
    path: "/fr/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___fr"
  }, {
    path: "/fr/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___fr"
  }, {
    path: "/fr/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___fr"
  }, {
    path: "/fr/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___fr"
  }, {
    path: "/fr/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___fr"
  }, {
    path: "/fr/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___fr"
  }, {
    path: "/fr/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___fr"
  }, {
    path: "/fr/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___fr"
  }, {
    path: "/fr/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___fr"
  }, {
    path: "/fr/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___fr"
  }, {
    path: "/fr/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___fr"
  }, {
    path: "/fr/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___fr"
  }, {
    path: "/fr/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___fr"
  }, {
    path: "/fr/shop/address",
    component: _30cb2d18,
    name: "shop-address___fr"
  }, {
    path: "/fr/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___fr"
  }, {
    path: "/fr/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___fr"
  }, {
    path: "/fr/shop/signup",
    component: _ace73470,
    name: "shop-signup___fr"
  }, {
    path: "/fr/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___fr"
  }, {
    path: "/nl-be/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___nl-be"
  }, {
    path: "/nl-be/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___nl-be"
  }, {
    path: "/nl-be/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___nl-be"
  }, {
    path: "/nl-be/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___nl-be"
  }, {
    path: "/nl-be/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___nl-be"
  }, {
    path: "/nl-be/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___nl-be"
  }, {
    path: "/nl-be/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___nl-be"
  }, {
    path: "/nl-be/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___nl-be"
  }, {
    path: "/nl-be/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___nl-be"
  }, {
    path: "/nl-be/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___nl-be"
  }, {
    path: "/nl-be/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___nl-be"
  }, {
    path: "/nl-be/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___nl-be"
  }, {
    path: "/nl-be/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___nl-be"
  }, {
    path: "/nl-be/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___nl-be"
  }, {
    path: "/nl-be/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___nl-be"
  }, {
    path: "/nl-be/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___nl-be"
  }, {
    path: "/nl-be/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___nl-be"
  }, {
    path: "/nl-be/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___nl-be"
  }, {
    path: "/nl-be/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___nl-be"
  }, {
    path: "/nl-be/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___nl-be"
  }, {
    path: "/nl-be/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___nl-be"
  }, {
    path: "/nl-be/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___nl-be"
  }, {
    path: "/nl-be/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___nl-be"
  }, {
    path: "/nl-be/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___nl-be"
  }, {
    path: "/nl-be/shop/address",
    component: _30cb2d18,
    name: "shop-address___nl-be"
  }, {
    path: "/nl-be/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___nl-be"
  }, {
    path: "/nl-be/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___nl-be"
  }, {
    path: "/nl-be/shop/signup",
    component: _ace73470,
    name: "shop-signup___nl-be"
  }, {
    path: "/nl-be/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___nl-be"
  }, {
    path: "/nl/checkout/oops",
    component: _276c3d7a,
    name: "checkout-oops___nl"
  }, {
    path: "/nl/checkout/success",
    component: _ed3e805a,
    name: "checkout-success___nl"
  }, {
    path: "/nl/checkout/thankyou",
    component: _3aca8d55,
    name: "checkout-thankyou___nl"
  }, {
    path: "/nl/dashboard/history",
    component: _4b273f60,
    name: "dashboard-history___nl"
  }, {
    path: "/nl/dashboard/mealplan",
    component: _7ba45360,
    name: "dashboard-mealplan___nl"
  }, {
    path: "/nl/dashboard/pets",
    component: _3921ab28,
    name: "dashboard-pets___nl"
  }, {
    path: "/nl/dashboard/profile",
    component: _5fef1a36,
    name: "dashboard-profile___nl"
  }, {
    path: "/nl/dashboard/share",
    component: _18c0b1ca,
    name: "dashboard-share___nl"
  }, {
    path: "/nl/dashboard/shop",
    component: _06c561ea,
    name: "dashboard-shop___nl"
  }, {
    path: "/nl/dashboard/subscription-settings",
    component: _387acc0f,
    name: "dashboard-subscription-settings___nl"
  }, {
    path: "/nl/dashboard/wallet",
    component: _fec58b26,
    name: "dashboard-wallet___nl"
  }, {
    path: "/nl/ehealth/activity",
    component: _48aed714,
    name: "ehealth-activity___nl"
  }, {
    path: "/nl/ehealth/change-complaints",
    component: _63b253e4,
    name: "ehealth-change-complaints___nl"
  }, {
    path: "/nl/ehealth/change-condition",
    component: _d43c8bb6,
    name: "ehealth-change-condition___nl"
  }, {
    path: "/nl/ehealth/change-pet",
    component: _671bb9a9,
    name: "ehealth-change-pet___nl"
  }, {
    path: "/nl/ehealth/discover",
    component: _7e79c760,
    name: "ehealth-discover___nl"
  }, {
    path: "/nl/ehealth/passport",
    component: _73ce180e,
    name: "ehealth-passport___nl"
  }, {
    path: "/nl/ehealth/planning",
    component: _04ba31c4,
    name: "ehealth-planning___nl"
  }, {
    path: "/nl/ehealth/symptom-checker",
    component: _d953160c,
    name: "ehealth-symptom-checker___nl"
  }, {
    path: "/nl/insurance/calculate",
    component: _a9615230,
    name: "insurance-calculate___nl"
  }, {
    path: "/nl/insurance/thankyou",
    component: _018f53e3,
    name: "insurance-thankyou___nl"
  }, {
    path: "/nl/questionnaires/cat",
    component: _5e4bb560,
    name: "questionnaires-cat___nl"
  }, {
    path: "/nl/questionnaires/dog",
    component: _1ced07b6,
    name: "questionnaires-dog___nl"
  }, {
    path: "/nl/questionnaires/profile",
    component: _81cb66ba,
    name: "questionnaires-profile___nl"
  }, {
    path: "/nl/shop/address",
    component: _30cb2d18,
    name: "shop-address___nl"
  }, {
    path: "/nl/shop/cart",
    component: _1e5dd1d0,
    name: "shop-cart___nl"
  }, {
    path: "/nl/shop/delivery",
    component: _27381ca4,
    name: "shop-delivery___nl"
  }, {
    path: "/nl/shop/signup",
    component: _ace73470,
    name: "shop-signup___nl"
  }, {
    path: "/nl/shop/thankyou",
    component: _6d27a525,
    name: "shop-thankyou___nl"
  }, {
    path: "/be-fr/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___be-fr"
  }, {
    path: "/be-nl/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___be-nl"
  }, {
    path: "/de/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___de"
  }, {
    path: "/en/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___en"
  }, {
    path: "/fr-be/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___fr-be"
  }, {
    path: "/fr/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___fr"
  }, {
    path: "/nl-be/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___nl-be"
  }, {
    path: "/nl/dashboard/manage/quantities",
    component: _4ad4f88f,
    name: "dashboard-manage-quantities___nl"
  }, {
    path: "/",
    component: _9d7579ee,
    name: "index___nl-be___default"
  }, {
    path: "/be-fr/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___be-fr"
  }, {
    path: "/be-fr/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___be-fr"
  }, {
    path: "/be-nl/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___be-nl"
  }, {
    path: "/be-nl/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___be-nl"
  }, {
    path: "/de/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___de"
  }, {
    path: "/de/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___de"
  }, {
    path: "/en/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___en"
  }, {
    path: "/en/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___en"
  }, {
    path: "/fr-be/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___fr-be"
  }, {
    path: "/fr-be/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___fr-be"
  }, {
    path: "/fr/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___fr"
  }, {
    path: "/fr/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___fr"
  }, {
    path: "/nl-be/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___nl-be"
  }, {
    path: "/nl-be/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___nl-be"
  }, {
    path: "/nl/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___nl"
  }, {
    path: "/nl/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___nl"
  }, {
    path: "/reset-password/:code?",
    component: _4288d3af,
    name: "reset-password-code___nl-be___default"
  }, {
    path: "/shop/:productName",
    component: _48f298ea,
    name: "shop-productName___nl-be___default"
  }, {
    path: "/registreren*",
    redirect: "/signup*",
    name: "registrerenDefaultLang"
  }, {
    path: "/:lang/registreren*",
    redirect: "/:lang/signup*",
    name: "registreren"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
