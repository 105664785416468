<template>
    <Menu v-if="$localization.currentCountry" v-model="menu" :top="large" left0 right0 class="w-full">
        <template v-slot:activator>
            <Button v-if="large" wide @click="menu = !menu" :color="white ? 'white' : light ? 'cream' : undefined" outlined icon="mdi:chevron-up" :testid="testid">
                <div class="flex justify-center items-center">
                    <img class="flag" :src="`/flags/${$localization.currentCountry.short_name.toLowerCase()}.png`" />
                    <span>{{ $localization.currentCountry.name }}</span>
                </div>
            </Button>
            <button v-else @click="menu = !menu" :class="{ country: true, light: light }" :data-testid="testid">
                <div class="centralAligner grow min-w-[70px]">
                    <img class="flag" :src="`/flags/${$localization.currentCountry.short_name.toLowerCase()}.png`" />
                    <div class="!mr-auto">{{ $localization.currentCountry.name }}</div>
                    <icon icon="mdi:chevron-down" />
                </div>
            </button>
        </template>
        <div :class="['otherCountryWrapper rounded-sm my-1 text-black overflow-hidden shadow-lg whitespace-nowrap', { 'h4 large': large }]">
            <div v-for="item in otherChoices">
                <button class="otherCountry" @click="$on.click || item.onClick()" :data-testid="item.testid">
                    <div class="centralAligner">
                        <img class="flag" :src="`/flags/${item.flag}.png`" />
                        <span>{{ item.name }}</span>
                    </div>
                </button>
            </div>
        </div>
    </Menu>
</template>

<script>
    import Button from "~/components/library/Button.vue"
    import Menu from "~/components/library/Menu.vue"

    export default {
        name: "CountrySelector",
        components: { Button, Menu },
        props: {
            light: Boolean,
            path: {
                type: String,
                required: false,
            },
            params: {
                type: Object,
                default: () => ({}),
            },
            large: Boolean,
            white: Boolean,
            testid: {
                type: String,
                required: false,
            },
        },
        data: () => ({
            menu: false,
        }),
        computed: {
            testidPrefix() {
                return this.testid ? `${this.testid}::` : ""
            },
            otherChoices() {
                const $vm = this
                return this.$localization.countries
                    .filter(item => this.$localization.currentCountry.short_name !== item.short_name)
                    .flatMap(x =>
                        x.short_name === "BE"
                            ? this.$localization.languages
                                  .filter(item => x.languages.includes(item.url))
                                  .map(item => ({
                                      name: `${x.name} (${item.iso_code})`,
                                      flag: x.short_name.toLowerCase(),
                                      onClick: () => $vm.$localization.changeCountry(x, item),
                                      testid: this.testidPrefix + `country.${item.iso_code.toLowerCase()}`,
                                  }))
                            : [
                                  {
                                      name: x.name,
                                      flag: x.short_name.toLowerCase(),
                                      onClick: () => $vm.$localization.changeCountry(x),
                                      testid: this.testidPrefix + `country.${x.short_name.toLowerCase()}`,
                                  },
                              ],
                    )
            },
        },
        methods: {
            async changeCountry(country, language) {
                let path = this.path
                this.$firebaseAuth.signOut()
                await this.$store.dispatch("authentication/logout")
                this.$axios.setToken(null)
                this.$helpers.redirect.applyRedirect(this.$router, {
                    baseTo: `http://${country.frontend_domain_name}`,
                    pathTo: (language ? `/${language.iso_code.toLowerCase()}` : "") + (path || `/${window.location.pathname.split("/").slice(2).join("/")}`),
                    keepParams: true,
                    additionalParams: this.params,
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .country,
    .otherCountry {
        height: 36px;
        padding-left: 16px;
        padding-right: 10px;
        line-height: normal;
        width: 100%;
    }
    .country {
        border: 1px solid black;
        border-radius: 20px;
    }
    .country.light {
        color: white;
        border-color: white;
    }
    .centralAligner {
        display: flex;
        > * {
            margin: auto 0;
        }
    }
    .country:hover,
    .otherCountry:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    .flag {
        height: 14px;
        width: 21px;
        border-radius: 3px;
        margin-right: 5px;
    }
    .otherCountryWrapper {
        display: flex;
        flex-direction: column;
        background-color: var(--color-cream);
        &.large {
            padding: 10px 0;
            .otherCountry {
                padding: 0 var(--space-1);
            }
        }
    }
</style>
