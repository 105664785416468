<template>
    <svg v-bind="$attrs" v-on="$listeners" :class="['animate-spin', large ? 'h-[158px] w-[158px]' : 'h-[24px] w-[24px]']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity" cx="12" cy="12" r="10" stroke="currentColor" :stroke-width="large ? '0.5' : '4'" stroke-dasharray="40, 200"></circle>
    </svg>
</template>

<script>
    export default {
        props: {
            large: Boolean,
        },
    }
</script>
