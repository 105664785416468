<template>
    <BaseLayout>
        <Header
            color="red"
            :steps="
                [
                    { href: this.localePath('/insurance'), label: $t('About you') },
                    { href: this.localePath('/insurance/calculate'), label: $t('About your animal') },
                    { href: this.localePath('/insurance/result'), label: $t('Formulas') },
                    { href: this.localePath('/insurance/thankyou'), label: $t('Application') },
                ][($t('About you'), $t('About your animal'), $t('Formulas'), $t('Application'))]
            "
        >
            <a :href="menu['Insurance']" class="flex justify-center mx-auto h-full">
                <img class="logos w-[76px]" src="/logo_white.svg" alt="Just Russel" />
                <div class="flex items-center text-cream mx-1">✕</div>
                <img class="logos w-[76px]" src="/Belfius-direct.webp" alt="Belfius Direct Insurances" />
            </a>
        </Header>
        <div class="flex flex-col grow">
            <nuxt />
        </div>
    </BaseLayout>
</template>

<script>
    import PollSubscriptionMixin from "~/components/mixins/PollSubscriptionMixin"
    import CustomerVisitMixin from "~/components/mixins/CustomerVisitMixin"
    import WordpressLinks from "~/components/mixins/WordpressLinks.js"

    import LayoutComponent from "~/components/LayoutComponent.vue"
    import Header from "~/components/library/Header.vue"
    import BaseLayout from "~/components/library/BaseLayout.vue"

    export default {
        name: "questionnaire",
        components: { Header, LayoutComponent, BaseLayout },
        mixins: [PollSubscriptionMixin, CustomerVisitMixin(), WordpressLinks],
    }
</script>

<style lang="scss" scoped>
    .logos {
        height: 100%;
        object-fit: contain;
        &:first-child {
            object-position: right;
        }
        &:last-child {
            object-position: left;
        }
    }
</style>
