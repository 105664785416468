import { getCookie } from "~/helpers/CookieHelper"

export default function ({ $gtm, $config, $axios }) {
    const AwinEvents = {}

    AwinEvents.sale = (customerId, delivery) => {
        if (window.localStorage.getItem(`aw-sale_${customerId}`) === "fired") {
            return
        }
        window.localStorage.setItem(`aw-sale_${customerId}`, "fired")

        // General conversion (Mandatory)
        $gtm.push({
            event: "AwinSalesConversion",
            env: $config.env,
            awin_data: {
                orderRef: this.$helpers.extractId(delivery.url),
                amount: delivery.price_excl_vat / 100,
                currency: "EUR",
                voucher: delivery.couponcodes ? delivery.couponcodes.join(",") : "",
            },
            affiliate_network_data: {
                name: "Awin",
            },
        })

        // Server to server conversion (Mandatory)
        $axios
            .$post(`/awin-conversions/sale/`, {
                orderRef: this.$helpers.extractId(delivery.url),
                amount: delivery.price_excl_vat / 100,
                currency: "EUR",
                cks: getCookie("awc"),
                vc: delivery.couponcodes ? delivery.couponcodes.join(",") : "",
            })
            .then(data => {
                //
            })
    }

    return {
        events: AwinEvents,
    }
}
