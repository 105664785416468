<template>
    <BaseLayout>
        <Header wordpressLogoLink />
        <nuxt />
    </BaseLayout>
</template>

<script>
    import Header from "~/components/library/Header.vue"
    import BaseLayout from "~/components/library/BaseLayout.vue"
    import CustomerVisitMixin from "~/components/mixins/CustomerVisitMixin"

    export default {
        name: "DrawerOnRightLayout",
        components: { Header, BaseLayout },
        mixins: [CustomerVisitMixin()],
    }
</script>
