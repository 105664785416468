<template>
    <BaseLayout>
        <DashboardHeader :steps="[{ label: '' }, { label: '' }, { label: '' }, { label: '' }]" />
        <nuxt />
    </BaseLayout>
</template>

<script>
    import CustomerVisitMixin from "~/components/mixins/CustomerVisitMixin"
    import BaseLayout from "~/components/library/BaseLayout.vue"
    import DashboardHeader from "~/components/features/dashboard/DashboardHeader.vue"

    export default {
        components: { DashboardHeader, BaseLayout },
        mixins: [CustomerVisitMixin()],
    }
</script>
