export default {
    mixins: [],
    data: () => ({
        menuUrls: {
            Home: { nl: "/", fr: "/", de: "/" },

            "Our concept": { nl: "/ons-verhaal", fr: "/comment-ca-marche", de: "/wie-es-funktioniert" },
            "Our flexible subscription plan": { nl: "/flexible-abonnement", fr: "/abonnement-flexible", de: "/flexibles-abonnement" },
            "Our app": { nl: "/onze-app", fr: "/notre-application", de: "/unsere-applikation" },
            "Our pricing": { nl: "/prijzen", fr: "/nos-prix", de: "/unsere-preise" },
            "Tailor-made dog food": { nl: "/hondenvoer-op-maat", fr: "/nourriture-pour-chien", de: "/hundefutter", en: "/customised-dog-food/" },
            "Tailor-made cat food": { nl: "/kattenvoer-op-maat", fr: "/nourriture-pour-chat", de: "/katzenfutter/", en: "/customised-cat-food/" },
            "Dog food for puppy": { nl: "/hondenvoer-voor-puppy", fr: "/nourriture-pour-chiot", de: "/welpenfutter", en: "/puppy-food/" },
            Reviews: { nl: "/reviews", fr: "/avis", de: "/erfahrungen" },
            Help: { nl: "/help", fr: "/aide", de: "/help", en: "/help-2" },

            Blog: { nl: "/blog", fr: "/blog", de: "/blog", en: "/blog" },
            "How it works": { nl: "/hoe-het-werkt", fr: "/comment-ca-marche", de: "/wie-es-funktioniert", en: "/our-concept/" },
            "Breeder Program": { nl: "/fokkerprogramma", fr: "", de: "" },
            "Puppy kit": { nl: "/puppypakket", fr: "", de: "/welpenrudel", en: "/puppy-food/" },
            "General Conditions": { nl: "/algemene-voorwaarden", fr: "/conditions-generales", de: "/agb" },
            "Privacy policy": { nl: "/privacy-policy", fr: "/politique-de-confidentialite", de: "/datenschutz" },

            "Healthy dog food": { nl: "/gezonde-hondenvoeding", fr: "", de: "/gesundes-hundefutter/" },
            "Senior dog food": { nl: "/senior-hondenvoer", fr: "", de: "/senioren-hundefutter" },
            "Dog food for digestive health": { nl: "/hondenvoeding-voor-gezonde-stoelgang", fr: "", de: "", en: "/healthy-bowel-movement/" },
            "Hypoallergenic dog food": { nl: "/hypoallergeen-hondenvoer", fr: "", de: "/hypoallergenes-gundefutter", en: "/hypoallergenic-dog-food/" },
            "Dog diet food": { nl: "/dieetvoeding", fr: "", de: "", en: "/diet-food-for-dog/" },
            "Dog food per breed": { nl: "/hondenrassen", fr: "", de: "" },

            "Our team": { nl: "/ons-verhaal", fr: "/qui-sommes-nous", de: "/euber-uns", en: "/our-story/" },
            Career: { nl: "/werken-bij-just-russel", fr: "", de: "" },

            Insurance: { nl: "/hondenverzekering", fr: "/assurance-chien", de: "/hondenverzekering" },

            Health: { nl: "/blog/category/gezondheid-hond", fr: "/blog", de: "/blog/category/gesundheit" },
            Food: { nl: "/blog/category/voeding", fr: "/blog", de: "/blog/category/ernaehrung" },
            "Must-Have": { nl: "/blog", fr: "/blog", de: "/blog/category/must-haves" },
            News: { nl: "/blog/category/hondennieuws", fr: "/blog", de: "/blog/category/hundenews" },
            Puppy: { nl: "/blog/category/puppy", fr: "/blog", de: "/blog/category/welpe" },
        },
    }),
    computed: {
        menu() {
            let primaryLanguageSubtag = (this.$i18n.localeProperties.iso || "nl").slice(0, 2)
            let menu = {}
            for (let label in this.menuUrls) {
                let wordpressUrlPrefix = this.$localization.currentLanguage?.wordpress_slug !== "/" ? this.$localization.currentLanguage?.wordpress_slug : ""
                let url = this.menuUrls[label][primaryLanguageSubtag] ? "https://" + this.$localization.currentCountry?.wordpress_domain_name + wordpressUrlPrefix + this.menuUrls[label][primaryLanguageSubtag] : ""
                menu[label] = url
            }
            return menu
        },
    },
}
