import TradeTrackers from "./TradeTrackers"
import Daisycon from "./Daisycon"
import Awin from "./Awin"
import { cookieExists, getCookie } from "~/helpers/CookieHelper"

export default function ({ $gtm, $config, $axios }, inject) {
    const ifCookieExists = cb => {
        if (cookieExists("JR_AN")) {
            const cookieValue = getCookie("JR_AN")
            const affiliateNetworkName = cookieValue.split("_")[0]
            // eslint-disable-next-line standard/no-callback-literal
            return cb(true, affiliateNetworkName.toLowerCase())
        } else {
            // eslint-disable-next-line standard/no-callback-literal
            return cb(false, null)
        }
    }

    const ifLinkedToCustomer = (customer, cb) => {
        const affiliateNetworkName = customer.referral_affiliate
        if (affiliateNetworkName) {
            // eslint-disable-next-line standard/no-callback-literal
            return cb(true, affiliateNetworkName.toLowerCase())
        }
        // eslint-disable-next-line standard/no-callback-literal
        return cb(false, null)
    }

    const validateDelivery = (customer, delivery, cb) => {
        // Validates if the customer is linked to an affiliate network or a cookie exists
        // Affiliate network linked to customer has priority
        let affiliateNetwork = null

        affiliateNetwork = ifLinkedToCustomer(customer, (linked, affiliateNetworkName) => {
            return linked ? affiliateNetworkName : null
        })

        affiliateNetwork =
            affiliateNetwork ||
            ifCookieExists((exists, affiliateNetworkName) => {
                return exists ? affiliateNetworkName : null
            })

        if (affiliateNetwork) {
            // eslint-disable-next-line standard/no-callback-literal
            return cb(true, affiliateNetwork.toLowerCase())
        }

        // eslint-disable-next-line standard/no-callback-literal
        return cb(false, null)
    }

    inject("affiliateNetworks", {
        ifCookieExists: ifCookieExists,
        ifLinkedToCustomer: ifLinkedToCustomer,
        validateDelivery: validateDelivery,
        tradeTrackers: TradeTrackers({ $gtm, $config }),
        daisycon: Daisycon({ $gtm, $config }),
        awin: Awin({ $gtm, $config, $axios }),
    })
}
