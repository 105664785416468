export default function (context) {
    if (window.location.hostname === "app.buddybites.be") {
        const path = window.location.pathname.trimRight("/")

        if (path === "/nl/login") {
            window.location = "https://app.justrussel.com/signin/?store=buddybites"
        } else {
            window.location = "https://app.justrussel.com/signup/?store=buddybites"
        }
    }
}
