export default ({ app }) => {
    const persisted_query_params = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "customer_override"]
    // Every time the route changes (fired on initialization too)
    app.router.beforeEach((to, from, next) => {
        const hasMissingParams = persisted_query_params.some(param => {
            return Object.keys(from.query).includes(param) && !Object.keys(to.query).includes(param)
        })
        if (hasMissingParams) {
            const newQuery = Object.assign({}, to.query)
            persisted_query_params.forEach(param => {
                newQuery[param] = from.query[param]
            })
            return next({
                path: to.path,
                query: newQuery,
            })
        }

        // PERSIST CUSTOMER OVERRIDE
        if (from.query.customer_override && !to.query.customer_override) {
            return next({
                path: to.path,
                query: Object.assign(to.query, {
                    customer_override: from.query.customer_override,
                }),
            })
        }
        if (to.query.customer_override && from?.query?.customer_override !== to.query.customer_override) {
            app.store.commit("api/customer/reset")
            return next()
        }

        if (from.query._ga && !to.query._ga) {
            return next({
                path: to.path,
                query: {
                    ...to.query,
                    _ga: from.query._ga,
                },
            })
        }

        if (from.query.store && !to.query.store) {
            return next({
                path: to.path,
                query: {
                    ...to.query,
                    store: from.query.store,
                },
            })
        }

        // FORCE ONBOARDING TYPE
        const defaultOnboarding = null
        const onboardingRoutes = ["/signup", "/questionnaires", "/checkout-dashboard", "/checkout-price", "/checkout-profile"]
        const excludedRoutes = ["/dashboard"]
        if (!from.query.onboarding && !defaultOnboarding) {
            return next()
        }
        // else if (onboardingRoutes.includes(to.path) && (!to.query.onboarding)) {
        else if (!to.query.onboarding) {
            return next({
                path: to.path,
                query: {
                    ...to.query,
                    onboarding: from.query.onboarding || defaultOnboarding,
                },
            })
        }
        // else if (excludedRoutes.includes(to.path) && Object.keys(to.query).includes('onboarding')) {
        //   delete to.query.onboarding
        //   return next({
        //     path: to.path,
        //     query: to.query
        //   })
        // }
        else {
            return next()
        }
    })
}
