import { debounce } from "lodash"

export const cloneDeep = obj => {
    return JSON.parse(JSON.stringify(obj))
}

export const addHours = (hours, initialDate = null) => {
    const date = new Date(initialDate || new Date())
    date.setTime(date.getTime() + hours * 60 * 60 * 1000)
    return date
}

export const addDays = (days, initialDate = null) => {
    const date = new Date(initialDate || new Date().setHours(0, 0, 0, 0))
    let d = new Date(date)
    d.setDate(d.getDate() + days)
    return d
}

export const addMonths = (months, initialDate = null) => {
    const date = new Date(initialDate || new Date().setHours(0, 0, 0, 0))
    let d = date.getDate()
    date.setMonth(date.getMonth() + months)
    if (date.getDate() !== d) {
        date.setDate(0)
    }
    return date
}

export const extractId = (url, clean) => {
    console.debug(`extracting id from url '${url}'`)
    let reg = /\/((hid_[A-Za-z0-9]{4,})|([A-Z0-9]{8,}))/
    let id = url.match(reg)
    if (!id) {
        console.error(`could not find id in url '${url}'`)
        return url
    }

    id = id[0].replace("/", "")
    console.debug(`found id in url '${url}': '${id}'`)
    return id
}

export const extractNonHashedId = url => {
    return url.split("/").filter(Boolean).reverse()[0]
}
