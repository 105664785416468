<template>
    <div class="mainwrapper">
        <LayoutComponent>
            <nuxt />
        </LayoutComponent>
    </div>
</template>

<script>
    import LayoutComponent from "../components/LayoutComponent"
    import LoadingIndicatorComponent from "../components/shared/LoadingIndicatorComponent"
    export default {
        name: "AfterMandateRedirect",
        components: { LoadingIndicatorComponent, LayoutComponent },
    }
</script>

<style scoped></style>
