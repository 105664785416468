// Guard to ensure that the user has ever been subscribed.
// There is a possibility that a user is fully authenticated with a password,
// but still has never subscribed to a plan. This can happen for example when a user
// got to the checkout page but never completed the payment.

export default async function ({ app, redirect, route, store }) {
    await store.dispatch("api/recurringDeliveries/get")

    if (!store.getters["api/hasActiveSubscription"]) {
        return redirect({
            path: app.localePath("pet-overview"),
        })
    }
}
