<template>
    <div data-app id="app" :key="$localization.locale" class="flex flex-col relative min-h-screen">
        <slot name="header"></slot>
        <div class="all-content-container">
            <slot></slot>
        </div>
        <div v-if="countryPopup">
            <CountrySelectorPopup detectIp />
        </div>
    </div>
</template>

<script>
    import CountrySelectorPopup from "~/components/features/language/CountrySelectorPopup.vue"
    export default {
        name: "LayoutComponent",
        components: { CountrySelectorPopup },
        props: {
            countryPopup: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped lang="scss">
    .all-content-container {
        padding-top: 0 !important;
        //background-color: var(--color-cream);
        z-index: 200;
        height: max-content;
        /*overflow-y: scroll;*/
        overflow-x: hidden;
        flex-grow: 1;

        &::-webkit-scrollbar {
            display: none !important;
        }

        display: flex;
        flex-direction: column;
    }

    .all-content-container.bottomnav {
        padding-bottom: 96px !important;
    }
</style>
