import { setCookie, getAllCookies, cookieExists, getCookie, cookieExistsRegex, getCookiesRegex } from "~/helpers/CookieHelper"
import { applyRedirect, compileRedirectUrl } from "~/helpers/RedirectHelper"
import { capitalizeFirstLetter, capitalizeEachWord, capitalizeFirstWordAndInferTheOther, titleCase, currency, formatCurrency, getSummationString } from "~/helpers/FormatHelper"
import { cloneDeep, addHours, addDays, addMonths, extractId, extractNonHashedId, debounceAsync } from "~/helpers/Functions"
import { getLeadSource } from "~/helpers/LeadsourceHelper"

export default (context, inject) => {
    const cookie = {
        setCookie,
        getAllCookies,
        cookieExists,
        getCookie,
        cookieExistsRegex,
        getCookiesRegex,
    }

    const format = {
        capitalizeFirstLetter,
        capitalizeEachWord,
        capitalizeFirstWordAndInferTheOther,
        titleCase,
        currency: currency(context.$localization),
        formatCurrency: formatCurrency(context.$localization),
        getSummationString,
    }

    const date = {
        addHours,
        addDays,
        addMonths,
    }

    const redirect = {
        applyRedirect,
        compileRedirectUrl,
    }

    const leadsource = {
        getLeadSource,
    }

    const helpers = {
        cloneDeep,
        cookie,
        format,
        date,
        extractId,
        extractNonHashedId,
        redirect,
        leadsource,
    }

    inject("helpers", helpers)
}
