<template>
    <Popup v-if="customerCountry || !detectIp" contentClass="!p-0 countrySelectorPopup !overflow-visible" v-model="dialog" @input="handleStay">
        <div class="flex md:flex-row flex-col-reverse">
            <div :class="['iconWrapper flex flex-col items-center p-1 md:p-3 bg-red rounded-b md:rounded-l md:rounded-br-none']">
                <icon icon="mdi:map-marker-radius" class="icon" />
                <img class="mt-auto hidden md:block w-[80px]" src="/logo_white.svg" />
            </div>
            <div class="countrySelector flex flex-col items-center w-full p-3 pt-4">
                <div v-if="detectIp" class="flex flex-col items-center">
                    <h2 class="h1 mb-3 md:mb-4 text-center md:text-left">{{ $t("It seems that you are visiting {store_name} from abroad.", { store_name: "Just Russel" }) }}</h2>
                    <Button class="mb-3 md:mb-4" large @click="handleChange" testid="popup.redirect">
                        {{ $t("Go to {store_name} {country} ({language})", { store_name: "Just Russel", country: customerCountry.name, language: customerCountryLanguage.iso_code }) }}
                    </Button>
                    <div class="text-left w-full mb-1">{{ $t("Or choose your country from the list below:") }}</div>
                    <CountrySelector class="mb-2 w-full" country white testid="country-switcher" />
                    <div class="flex w-full">
                        <a class="underline cursor-pointer text-inherit ml-auto" @click="handleStay" data-testid="popup.stay_on_site">{{ $t("Stay on this site") }}</a>
                    </div>
                </div>
                <div v-else>
                    <h2 class="h1 mb-3 md:mb-4 text-center md:text-left">{{ $t("Would you like to order {store_name} in another country?", { store_name: "Just Russel" }) }}</h2>
                    <div class="text-left w-full mb-1">{{ $t("Choose your country from the list below:") }}</div>
                    <CountrySelector class="mb-2" country white :path="computedPath" :params="computedParams" testid="country-switcher" />
                    <div class="flex w-full">
                        <a class="underline cursor-pointer text-inherit ml-auto" @click="dialog = false" data-testid="popup.stay_on_site">{{ $t("Stay on this site") }}</a>
                    </div>
                </div>
            </div>
        </div>
    </Popup>
</template>

<script>
    import CountrySelector from "~/components/features/language/CountrySelector.vue"
    import Popup from "~/components/library/Popup.vue"
    import Button from "~/components/library/Button.vue"
    export default {
        name: "CountrySelectorPopup",
        components: { CountrySelector, Popup, Button },
        props: {
            detectIp: Boolean,
        },
        data() {
            return {
                dialog: false,
                customerCountryCode: null,
            }
        },
        async mounted() {
            let promises = [this.$store.dispatch("api/countries/get"), this.$store.dispatch("api/languages/get")]
            if (this.$store.getters["authentication/authenticated"]) {
                promises.push(this.$store.dispatch("api/customer/get"))
            }

            await Promise.all(promises)
            if (this.detectIp) {
                if (this.$store.getters["authentication/authenticated"] && !this.$store.state.api.languageUpdatedByUser) {
                    this.$store.dispatch("api/customer/patch", {
                        url: this.$store.state.api.customer.url,
                        prefered_language: this.$localization.currentLanguage.url,
                    })
                }
                let ipDetection = null
                if (!this.$store.state.api.languageUpdatedByUser && process.env.NODE_ENV === "production") {
                    ipDetection = this.$axios.$get("https://api.ipregistry.co/?key=mb60y4loqhr3w8ex").then(payload => payload.location.country.code)
                } else if (!this.$store.state.api.languageUpdatedByUser) {
                    ipDetection = Promise.resolve("NL")
                }
                if (ipDetection) {
                    let short_name = await ipDetection
                    this.customerCountryCode = short_name.toUpperCase()
                    if (this.$localization.currentCountry.short_name !== this.customerCountry?.short_name) {
                        this.dialog = true
                    } else {
                        this.$store.commit("api/languageUpdatedByUser/set", true)
                    }
                }
            }
        },
        computed: {
            customerCountry() {
                return this.$localization.countries?.find(country => country.short_name === this.customerCountryCode)
            },
            customerCountryLanguage() {
                return this.$localization.languages?.find(language => language.url === this.customerCountry?.default_language)
            },
            computedParams() {
                return {
                    email: this.$store.state.authentication?.currentUser?.email,
                    firstname: this.$store.state.api.customer?.firstname,
                }
            },
            computedPath() {
                if (this.$route.path.includes("/shop")) return "/shop"
                if (this.$route.path.includes("/checkout")) return "/signup"
                return "/" + this.$route.path.split("/").slice(2).join("/")
            },
        },
        methods: {
            handleChange() {
                this.$localization.changeCountry(this.customerCountry)
                this.dialog = false
            },
            handleStay() {
                this.close()
                if (this.detectIp) this.$store.commit("api/languageUpdatedByUser/set", true)
            },
            close() {
                this.dialog = false
            },
            open() {
                this.dialog = true
            },
        },
    }
</script>

<style lang="scss">
    .v-dialog.countrySelectorPopup {
        max-width: 850px !important;
    }
</style>

<style scoped lang="scss">
    .icon {
        color: white !important;
        font-size: 80px !important;
        @media (max-width: 960px) {
            font-size: 40px !important;
            justify-content: start !important;
        }
    }
    .countrySelector h2 {
        font-size: 28px !important;
        line-height: 32px !important;
        @media (max-width: 960px) {
            font-size: 24px !important;
            line-height: 28px !important;
        }
    }
</style>
