<template>
    <div :class="['flex', 'flex-wrap', '!grow', '!shrink', '!basis-auto', '-mx-1', 'items-center', 'justify-center']" style="height: 100%">
        <div :class="['p-1', '!basis-full', '!max-w-full', 'text-center py-0']" v-if="logo">
            <img src="/logo_stacked.svg" alt="Logo" :height="size" />
        </div>
        <div :class="['p-1', 'grow', '!basis-0', '!max-w-full', wrapperClass, 'text-center py-0']">
            <Spinner />
        </div>
    </div>
</template>

<script>
    import Spinner from "../library/Spinner.vue"

    export default {
        name: "LoadingIndicatorComponent",
        props: {
            color: {
                type: String,
                required: false,
            },
            wrapperClass: {
                type: Object || String,
                required: false,
            },
            size: {
                type: Number | String,
                default: 50,
            },
            logo: {
                type: Boolean,
                default: false,
            },
        },
        components: { Spinner },
    }
</script>

<style scoped lang="scss"></style>
