<template>
    <Icon :icon="icon" />
</template>

<script>
    import { Icon } from "@iconify/vue2"
    export default {
        components: { Icon },
        props: {
            icon: String,
        },
    }
</script>
