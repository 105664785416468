<template>
    <footer>
        <div class="higherFooter">
            <section class="largest">
                <div class="flex flex-wrap !grow !shrink !basis-auto -mx-1">
                    <div class="p-1 !basis-full !max-w-full md:!basis-3/12 md:!max-w-3/12 text-center md:text-left">
                        <img loading="lazy" class="mb-3 w-[158px] h-[73px]" src="/logo_white.svg" alt="JustRussel logo-white" data-ll-status="loaded" />
                        <h2 class="text-left mb-1">{{ $t("Customer Service") }}</h2>
                        <a :href="$localization.currentLanguage.zendesk_link" target="_blank" class="mt-2">
                            <Button large>{{ $t("Get in touch") }}</Button>
                        </a>
                    </div>
                    <div class="p-1 !basis-full !max-w-full md:!basis-3/12 md:!max-w-3/12">
                        <h2>{{ $t("Our service") }}</h2>
                        <div v-for="linkName in ['How it works', 'Puppy kit', 'Help', 'Breeder Program', 'Blog', 'General Conditions', 'Privacy policy']" v-if="menu[linkName]">
                            <a :href="menu[linkName]">{{ $t(linkName) }}</a>
                        </div>
                    </div>
                    <div class="p-1 !basis-full !max-w-full md:!basis-3/12 md:!max-w-3/12">
                        <h2>{{ $t("Our nutrition") }}</h2>
                        <div v-for="linkName in ['Tailor-made cat food', 'Tailor-made dog food', 'Healthy dog food', 'Dog food for puppy', 'Senior dog food', 'Dog food for digestive health', 'Hypoallergenic dog food', 'Dog diet food', 'Dog food per breed']" v-if="menu[linkName]">
                            <a :href="menu[linkName]">{{ $t(linkName) }}</a>
                        </div>
                    </div>
                    <div class="p-1 !basis-full !max-w-full md:!basis-3/12 md:!max-w-3/12">
                        <h2>{{ $t("About us") }}</h2>
                        <div v-for="linkName in ['Our concept', 'Our team', 'Reviews', 'Career']" v-if="menu[linkName]">
                            <a :href="menu[linkName]">{{ $t(linkName) }}</a>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap !grow !shrink !basis-auto -mx-1" v-if="$localization.currentCountry">
                    <div class="p-1 !basis-full !max-w-full md:!basis-6/12 md:!max-w-[50%]">
                        <h2>{{ $t("Follow us online") }}</h2>
                        <div class="flex flex-wrap !grow !shrink !basis-auto -mx-1">
                            <div class="p-1 w-auto !basis-auto !max-w-full">
                                <a :href="$localization.currentCountry.facebook_link">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" aria-hidden="true" viewBox="0 0 512 512">
                                        <path d="M288 192v-38.1c0-17.2 3.8-25.9 30.5-25.9H352V64h-55.9c-68.5 0-91.1 31.4-91.1 85.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z" fill="currentColor"></path>
                                    </svg>
                                </a>
                            </div>
                            <div class="p-1 w-auto !basis-auto !max-w-full">
                                <a :href="$localization.currentCountry.instagram_link">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="svgIcon" aria-hidden="true" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M349.33 69.33a93.62 93.62 0 0 1 93.34 93.34v186.66a93.62 93.62 0 0 1-93.34 93.34H162.67a93.62 93.62 0 0 1-93.34-93.34V162.67a93.62 93.62 0 0 1 93.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32Z"></path>
                                        <path fill="currentColor" d="M377.33 162.67a28 28 0 1 1 28-28 27.94 27.94 0 0 1-28 28ZM256 181.33A74.67 74.67 0 1 1 181.33 256 74.75 74.75 0 0 1 256 181.33m0-37.33a112 112 0 1 0 112 112 112 112 0 0 0-112-112Z"></path>
                                    </svg>
                                </a>
                            </div>
                            <div class="p-1 w-auto !basis-auto !max-w-full">
                                <a :href="$localization.currentCountry.youtube_link">
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svgIcon" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9ZM207 353.89v-196.5l145 98.2Z"></path>
                                    </svg>
                                </a>
                            </div>
                            <div class="p-1 w-auto !basis-auto !max-w-full">
                                <a :href="$localization.currentCountry.tiktok_link">
                                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svgIcon" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5 132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 0 1-35.22 55.56 68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52 161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61Z"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="p-1">
                        <div>
                            <img loading="lazy" :src="`/certificates/${$localization.currentLanguage.iso_code && $localization.currentLanguage.iso_code.toUpperCase()}.png`" class="attachment-full size-full h-[115px] lazyloaded" alt="" sizes="(max-width: 838px) 100vw, 838px" data-ll-status="loaded" /><noscript><img loading="lazy" class="attachment-full size-full w-[225px] h-[115px]" alt="" sizes="(max-width: 838px) 100vw, 838px" /></noscript>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="lowerFooter">
            <div class="text-center mb-3">
                <Payments />
            </div>
            <div class="text-center">© Just Russel {{ year }}</div>
        </div>
    </footer>
</template>

<script>
    import WordpressLinks from "~/components/mixins/WordpressLinks.js"
    import Payments from "~/components/features/eshop/Payments.vue"
    import Button from "~/components/library/Button.vue"
    export default {
        name: "Footer",
        components: { Payments, Button },
        mixins: [WordpressLinks],
        computed: {
            year() {
                return new Date().getFullYear()
            },
        },
    }
</script>

<style lang="scss" scoped>
    .justify-between {
        justify-content: space-between;
    }
    .higherFooter {
        background-color: black;
        color: white;
        padding: 2rem 0;
    }
    .higherFooter .footerIcon {
        position: relative;
        top: 6px;
        margin-right: 0.25rem;
    }
    .higherFooter a {
        color: grey;
    }
    .higherFooter a:hover {
        color: var(--color-red);
    }
    .lowerFooter {
        padding: 2rem;
    }
    .svgIcon {
        height: 35px;
        width: 35px;
        padding: 5px;
        background-color: white;
        border-radius: 50%;
        color: black;
    }
</style>
