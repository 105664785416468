import Vue from "vue"
import * as Sentry from "@sentry/vue"
import { captureConsoleIntegration } from "@sentry/integrations"

export default function ({ app }) {
    const env = process.env.ENV.toLowerCase()
    const version = process.env.VERSION
    const sampleRates = {
        staging: 1.0,
        production: 0.25,
    }
    const traceSampleRate = {
        staging: 1.0,
        production: 0.25,
    }
    const replaySampleRate = {
        staging: 0.0,
        production: 0.0,
    }
    const replayErrorRate = {
        staging: 1.0,
        production: 0.25,
    }

    if (env === "staging" || env === "production") {
        Sentry.init({
            Vue: Vue,
            dsn: "https://e9438849318142739e35b55a3b720865@o1149608.ingest.sentry.io/4504089004081152",
            integrations: [Sentry.browserTracingIntegration({ router: app.router }), Sentry.replayIntegration(), captureConsoleIntegration({ levels: ["error", "assert"] })],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: traceSampleRate[env],
            sampleRate: sampleRates[env],
            logErrors: env !== "production",
            environment: env,
            release: version,
            replaysSessionSampleRate: replaySampleRate[env],
            replaysOnErrorSampleRate: replayErrorRate[env],
            ignoreErrors: ["Network Error", "Redirected when going from", "Navigation cancelled from", "Navigation aborted from", "Unexpected token '<'", "Uncaught SyntaxError: Unexpected token '<'", "No error message", "Tracker exists already"],
        })
    }
}
