export default function ({ $gtm, $config }) {
    const DaisyconEvents = {}

    DaisyconEvents.sale = (customerId, delivery) => {
        if (window.localStorage.getItem(`dc-sale_${customerId}`) === "fired") {
            return
        }
        window.localStorage.setItem(`dc-sale_${customerId}`, "fired")
        $gtm.push({
            event: "DaisyconSalesConversion",
            env: $config.env,
            dc_data: {
                transaction_id: this.$helpers.extractId(delivery.url),
                order_amount: delivery.price_excl_vat / 100,
                currency: "EUR",
            },
            affiliate_network_data: {
                name: "Daisycon",
            },
        })
    }

    return {
        events: DaisyconEvents,
    }
}
