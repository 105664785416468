import Vue from "vue"

import Card from "~/components/Card"
import Notification from "~/components/Notification"

import Icon from "~/components//library/Icon"

Vue.component("card", Card)
Vue.component("notification", Notification)

Vue.component("icon", Icon)
