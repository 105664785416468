<template>
    <div class="jr_notification">
        <slot name="dialogs"></slot>

        <card :bg="bg" :color="color">
            <div :class="['flex', 'flex-wrap', '!grow', '!shrink', '!basis-auto', '-mx-1']">
                <div :class="['p-1', 'w-auto', '!basis-auto', '!max-w-full', 'jr_notification__icon']">
                    <slot name="icon"></slot>
                </div>
                <div :class="['p-1', 'grow', '!basis-0', '!max-w-full']">
                    <p class="jr_notification__body mb-0">
                        <slot>
                            <span v-html="html"></span>
                        </slot>
                    </p>
                    <div :class="['flex', 'flex-wrap', '!grow', '!shrink', '!basis-auto', '-mx-1']">
                        <div :class="['p-1', 'w-auto', '!basis-auto', '!max-w-full', 'pl-0']" v-for="a in actions" v-if="actions">
                            <button class="jr_notification__action" @click="handleAction(a)">{{ a.text }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<script>
    // import PetMixin from './mixins/PetMixin'

    export default {
        // mixins: [PetMixin],
        props: {
            bg: {
                type: String,
                default: "yellow",
            },
            color: {
                type: String,
                default: "black",
            },
            html: {
                type: String,
                default: "This is a <strong>notification</strong>",
            },
            actions: {
                type: Array,
                required: false,
            },
        },
        methods: {
            handleAction(action) {
                if (action.type === "dialog") {
                    let dialog = this.$slots.dialogs.filter(d => d.data && d.data.ref === action.ref)
                    if (dialog.length > 0) {
                        dialog = dialog[0]
                        let subDialog = dialog.context.$refs[action.ref]
                        if (subDialog.length > 0) {
                            subDialog = subDialog[0]
                        }
                        if (subDialog) {
                            subDialog.openDialog()
                        }
                    }
                } else if (action.type === "method") {
                    const args = action.args || []
                    action.handler(...args)
                }
            },
        },
        data() {
            return {
                actionDialog: false,
            }
        },
    }
</script>

<style lang="scss" scoped>
    .jr_notification {
        margin-bottom: 15px;
        &__body,
        &__action {
            display: inline-block;
        }
        &__action {
            text-decoration: underline;
        }
        .row {
            margin: 0;
        }
        .col {
            padding-top: 0;
            padding-bottom: 0;
        }
        &__icon {
            padding-top: 5px !important;
        }
    }
</style>
