const middleware = {}

middleware['auth.client'] = require('../middleware/auth.client.js')
middleware['auth.client'] = middleware['auth.client'].default || middleware['auth.client']

middleware['buddybites'] = require('../middleware/buddybites.js')
middleware['buddybites'] = middleware['buddybites'].default || middleware['buddybites']

middleware['deprecated_urls'] = require('../middleware/deprecated_urls.js')
middleware['deprecated_urls'] = middleware['deprecated_urls'].default || middleware['deprecated_urls']

middleware['requireActiveSubscription'] = require('../middleware/requireActiveSubscription.js')
middleware['requireActiveSubscription'] = middleware['requireActiveSubscription'].default || middleware['requireActiveSubscription']

middleware['requireAuth'] = require('../middleware/requireAuth.js')
middleware['requireAuth'] = middleware['requireAuth'].default || middleware['requireAuth']

middleware['requireBelgium'] = require('../middleware/requireBelgium.js')
middleware['requireBelgium'] = middleware['requireBelgium'].default || middleware['requireBelgium']

middleware['requireEmail'] = require('../middleware/requireEmail.js')
middleware['requireEmail'] = middleware['requireEmail'].default || middleware['requireEmail']

middleware['requireNotAuth'] = require('../middleware/requireNotAuth.js')
middleware['requireNotAuth'] = middleware['requireNotAuth'].default || middleware['requireNotAuth']

middleware['requireValidLocale'] = require('../middleware/requireValidLocale.js')
middleware['requireValidLocale'] = middleware['requireValidLocale'].default || middleware['requireValidLocale']

export default middleware
