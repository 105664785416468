// Guard to ensure we have a valid email address for the user.
// A user can be authenticated without an email when they signing in with a social provider.
// If that is the case, then we redirect to a page where the user can enter their email address and then
// continue to the page they were trying to access.

export default async function ({ app, redirect, route, store }) {
    if (store.getters["authentication/authenticated"] && !store.getters["authentication/currentUser"].email) {
        return redirect({
            path: app.localePath("/questionnaires/profile"),
            query: { ...route.query, next: route.fullPath },
        })
    }
}
