// Transforms some data types so that the API will accept them.
function formatPayload(payload) {
    for (let key in payload) {
        let data = payload[key]
        if (data instanceof Date) {
            payload[key] = `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`
        }
    }

    return payload
}

/** Automatically create state, mutations and actions for api authEndpoints
 *  Endpoints have to be a key/url pair
 *  Url can be null to just create a default variable in the store with only a setter
 */
const promises = {}
export function api(endpoints = {}, nonArrayEndpointsKeys = []) {
    const isEndpoint = url => typeof url === "string" && url[0] === "/"

    const getDefaultState = () => {
        let defaultState = _.cloneDeep(endpoints)
        for (let key in defaultState) {
            if (isEndpoint(defaultState[key])) {
                defaultState[key] = nonArrayEndpointsKeys.includes(key) ? null : []
            }
        }
        return defaultState
    }

    const state = getDefaultState
    const mutations = {}
    const actions = {}

    for (let key in endpoints) {
        // Replace the state with the provided payload.
        function set(state, payload) {
            state[key] = payload instanceof Object ? _.cloneDeep(payload) : payload
        }

        function reset(state, payload) {
            for (let url in promises) if (url.includes(endpoints[key])) promises[url] = null
            state[key] = getDefaultState()[key]
        }

        const getNewStateFromData = (state, data) => {
            if (nonArrayEndpointsKeys.includes(key)) return data
            if (data instanceof Array) return data

            let newState = _.cloneDeep(state[key]) || []
            let dataIndex = newState.indexOf(newState.find(x => x.url === data.url))
            if (dataIndex !== -1) {
                newState[dataIndex] = data
            } else {
                newState.push(data)
            }
            return newState
        }

        async function get({ state, commit }, payload = { force: false, url: undefined }) {
            const loader = async url => {
                let result = await this.$axios.$get(url)
                let data = result.results || result
                commit(`${key}/set`, getNewStateFromData(state, data))
                return data
            }

            // Return the object if it is already in the store
            if (state[key] instanceof Array) {
                const existingObject = state[key].find(x => x.url === payload.url)
                if (existingObject) return _.cloneDeep(existingObject)
            }

            // Use the given object url or the base url if not provided
            let url = payload.url || endpoints[key]

            if (!promises[url] || payload.force) promises[url] = loader(url)
            return await promises[url]
        }

        async function post({ state, commit, dispatch }, payload) {
            let formatted_payload = formatPayload(payload)
            if (Object.values(payload).some(x => x instanceof File || x instanceof Buffer)) {
                const formData = new FormData()
                for (let key in formatted_payload) formData.append(key, formatted_payload[key])
                var data = await this.$axios.$post(endpoints[key], formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
            } else {
                var data = await this.$axios.$post(endpoints[key], formatted_payload)
            }
            commit(`${key}/set`, getNewStateFromData(state, data))
            return data
        }

        async function patch({ state, commit, dispatch }, payload) {
            let formatted_payload = formatPayload(payload)
            if (Object.values(payload).some(x => x instanceof File || x instanceof Buffer)) {
                const formData = new FormData()
                for (let key in formatted_payload) formData.append(key, formatted_payload[key])
                var data = await this.$axios.$patch(payload.url, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
            } else {
                var data = await this.$axios.$patch(payload.url, formatted_payload)
            }
            commit(`${key}/set`, getNewStateFromData(state, data))
            return data
        }

        async function remove({ state, commit }, payload) {
            let data = await this.$axios.$delete(payload.url)
            let newState = state[key] instanceof Array ? state[key].filter(x => x.url !== payload.url) : null
            commit(`${key}/set`, newState)
            return data
        }

        mutations[`${key}/set`] = set
        mutations[`${key}/reset`] = reset
        if (isEndpoint(endpoints[key])) {
            actions[`${key}/get`] = get
            actions[`${key}/post`] = post
            actions[`${key}/patch`] = patch
            actions[`${key}/delete`] = remove
        }
    }
    return { state, mutations, actions }
}
