import { auth } from "@/plugins/firebaseAuth.js"

export default function ({ app, store, $axios, error: nuxtError, redirect, route }) {
    $axios.onRequest(config => {
        if (app.router.currentRoute.query.customer_override) {
            let currentSearchParams = config.url.split("?")
            currentSearchParams = currentSearchParams.length === 2 ? currentSearchParams[1] : null
            const params = currentSearchParams ? new URLSearchParams(currentSearchParams) : new URLSearchParams()
            Object.keys(app.router.currentRoute.query).forEach(key => {
                params.append(key, app.router.currentRoute.query[key])
            })
            const baseUrl = config.url.split("?")[0]
            config.url = `${baseUrl}?${params.toString()}`
        }

        if (app.router.currentRoute.query.jrt) {
            config.headers.common["JR-Token"] = app.router.currentRoute.query.jrt
        }
    })
    $axios.onResponseError(async error => {
        const code = parseInt(error.response?.status)
        switch (code) {
            case 401:
                // TODO: attempt to refresh the token an do the request again if
                // successful.
                auth.signOut()
                await store.dispatch("authentication/logout")
                $axios.setToken(null)
                redirect({
                    path: app.localePath("signin"),
                    query: { ...route.query, next: route.fullPath },
                })
            case 503:
                nuxtError({
                    statusCode: error.response.status,
                    message: error.message,
                })
                break
            default:
                throw error
        }
    })
}
