export default ({ $gtm, app }) => {
    // Every time the route changes (fired on initialization too)
    setTimeout(() => {
        $gtm.init("GTM-PRQHRLQ")
    }, 250)
    app.router.beforeEach((to, from, next) => {
        // Set correct virtualPageUrl parameter in GTM Datalayer
        if (app.$gtm && !to.path.includes("questionnaires")) {
            let path = to.path
            const latestCharacter = path.charAt(path.length - 1)
            if (latestCharacter !== "/") {
                path += "/"
            }
            app.$gtm.push({
                virtualPageUrl: path,
                virtualPageTitle: "Just Russel App",
            })
        }
        return next()
    })
}
