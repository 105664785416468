import Vue from "vue"

export default function ({ $axios, $helpers, $gtm, $config }, inject) {
    const FacebookConversionEvents = {}
    FacebookConversionEvents.lead = () => {
        $axios.$post(`/fb-conversions/lead/`).then(data => {
            //
        })
    }
    FacebookConversionEvents.shopLead = () => {
        $axios.$post(`/fb-conversions/shop_lead/`).then(data => {
            //
        })
    }
    FacebookConversionEvents.addToCart = () => {
        $axios.$post(`/fb-conversions/add_to_cart/`).then(data => {
            //
        })
    }
    FacebookConversionEvents.shopAddToCart = data => {
        $axios.$post(`/fb-conversions/shop_add_to_cart/`, data).then(data => {
            //
        })
    }
    FacebookConversionEvents.purchase = deliveryId => {
        $axios
            .$post(`/fb-conversions/purchase/`, {
                delivery_id: deliveryId,
            })
            .then(data => {
                //
            })
    }
    FacebookConversionEvents.shopPurchase = deliveryId => {
        $axios
            .$post(`/fb-conversions/shop_purchase/`, {
                delivery_id: deliveryId,
            })
            .then(data => {
                //
            })
    }
    FacebookConversionEvents.trialPurchase = deliveryId => {
        $axios
            .$post(`/fb-conversions/trial_purchase/`, {
                delivery_id: deliveryId,
            })
            .then(data => {
                //
            })
    }
    FacebookConversionEvents.insuranceLead = customer => {
        let customerId
        try {
            customerId = $helpers.extractId(customer.url)
        } catch (e) {
            customerId = null
        }
        $axios.$post(`/fb-conversions/insurance_lead/`, {
            customer_id: customerId,
        })
    }
    FacebookConversionEvents.insuranceCoronaDirectLead = customer => {
        let customerId
        try {
            customerId = $helpers.extractId(customer.url)
        } catch (e) {
            customerId = null
        }
        $axios
            .$post(`/fb-conversions/insurance_corona_direct_lead/`, {
                customer_id: customerId,
            })
            .then(data => {
                //
            })
    }

    inject("facebookConversionApi", {
        events: FacebookConversionEvents,
    })
}
