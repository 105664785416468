<template>
    <div
        class="transition-all duration-300"
        :class="{
            'opacity-0': !open && fadeIn,
            '-mt-5': !open && slideDown,
            '-mt-[400px]': !open && slideDownHard,
        }"
    >
        <slot v-if="showContent"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            value: Boolean,
            fadeIn: Boolean,
            slideDown: Boolean,
            slideDownHard: Boolean,
        },
        data: () => ({
            open: false,
            showContent: false,
            timeout: null,
        }),
        mounted() {
            // Force animation on first render to fix nested animations
            setTimeout(() => {
                if (this.value) {
                    this.open = true
                    this.showContent = true
                }
            })
        },
        watch: {
            value() {
                if (this.value) {
                    this.open = true
                    this.showContent = true
                    if (this.timeout) clearTimeout(this.timeout)
                } else {
                    this.open = false
                    this.timeout = setTimeout(() => {
                        this.showContent = false
                    }, 300)
                }
            },
        },
    }
</script>
