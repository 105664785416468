<template>
    <BaseLayout>
        <Header
            wordpressLogoLink
            light
            :steps="[
                { href: this.localePath('/pet-overview'), label: $t('My pets') },
                { href: this.localePath('/checkout-dashboard'), label: $t('Feeding plan'), allowNavigateForward: true },
                { href: this.localePath('/checkout-price'), label: $t('Shopping cart'), allowNavigateForward: true },
                { href: this.localePath('/checkout-profile'), label: $t('Order') },
            ]"
        />
        <nuxt />
    </BaseLayout>
</template>

<script>
    import Header from "~/components/library/Header.vue"
    import BaseLayout from "~/components/library/BaseLayout.vue"

    export default {
        components: { Header, BaseLayout },
        name: "QuestionnaireLayout",
    }
</script>
