export default {
    data() {
        return {
            outgoingLink: "/signin",
        }
    },
    computed: {
        nextUrl() {
            if (this.$route.path.includes("/dashboard")) return this.localePath({ path: "/dashboard" })
            if (this.$route.path.includes("/shop")) return this.localePath({ path: "/shop" })
            if (this.$route.path.includes("/insurance")) return this.localePath({ path: "/insurance" })
        },
    },
    methods: {
        login(preventRedirect = false) {
            let query = this.nextUrl && !preventRedirect ? { ...this.$route.query, next: this.nextUrl } : this.$route.query
            this.$router.push(this.localePath({ path: "/signin", query }))
        },

        async logout(preventRedirect = false) {
            this.$firebaseAuth.signOut()
            await this.$store.dispatch("authentication/logout")
            this.$axios.setToken(null)
            let query = this.nextUrl && !preventRedirect ? { ...this.$route.query, next: this.nextUrl } : this.$route.query
            let path = this.$route.path.includes("/shop") && !this.$route.path.includes("/dashboard") ? "/shop" : { path: "/signin", query }
            this.$router.push(this.localePath(path))
        },
    },
}
