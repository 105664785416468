import _cloneDeep from "lodash/cloneDeep"

export default {
    mixins: [],

    data() {
        return {
            cartLoaded: false,
            cart: {},
            productData: {},
            loadingProducts: false,
        }
    },

    methods: {
        async getCart() {
            const cartItems = _cloneDeep(this.$store.state.cart.items)

            for (const key in cartItems) {
                if (!cartItems[key].pets) cartItems[key].pets = []
                cartItems[key].title = this.toFullName(cartItems[key].product)
                cartItems[key].titleBoldHtml = `<b>${cartItems[key].title.split("(")[0].trim()}</b>`
                if (cartItems[key].title.split("(").length > 1) {
                    cartItems[key].titleBoldHtml += ` (${cartItems[key].title.split("(")[1].split(")")[0]})`
                }
                cartItems[key].description = cartItems[key].product.categorized_meta?.features?.title || ""
            }

            this.cart = cartItems

            this.cartLoaded = true
        },

        async updateCart(noPropagate) {
            await this.$store.commit("cart/setItems", this.cart)
            if (!noPropagate) {
                this.$nuxt.$emit("cartChange")
            }
        },

        addToCart(product, quantity, pets = []) {
            this.modal = false
            const cartItems = { ...this.$store.state.cart.items }

            if (cartItems[product.url]) {
                quantity = cartItems[product.url].quantity + quantity
            }
            cartItems[product.url] = {
                quantity,
                product,
                pets,
            }

            this.cart = cartItems
            this.updateCart()

            this.$tracking.events.shopAddToCart(product, quantity)
        },

        changeQuantity(product, quantity) {
            const cartItems = { ...this.$store.state.cart.items }
            const quantifyDiff = quantity - (cartItems[product.url].quantity || 0)

            cartItems[product.url] = {
                quantity,
                product: product,
                pets: cartItems[product.url].pets,
                title: cartItems[product.url].title,
                titleBoldHtml: cartItems[product.url].titleBoldHtml,
            }

            this.cart = cartItems
            this.updateCart()

            this.$tracking.events.shopAddToCart(product, quantifyDiff)
        },

        remove(product) {
            const currentQuantity = this.cart[product.url].quantity || 0

            const newCart = {}
            for (const key in this.cart) {
                if (key !== product.url) {
                    newCart[key] = this.cart[key]
                }
            }
            this.cart = newCart
            this.updateCart()

            this.$tracking.events.shopAddToCart(product, -currentQuantity)
        },

        getDiscountedPrice(product) {
            if (product.shop_discounted_price && product.shop_discounted_price !== product.shop_price) return product.shop_discounted_price
            let category = this.productCategories.find(category => category.url === product.category)
            if (!category) return product.shop_price
            if (category.shop_family_discount && category.shop_family_discount > 0 && this.familyMember) {
                return Math.round(product.shop_price * (1 - category.shop_family_discount / 100))
            }
            return product.shop_price
        },

        getUrl(productCategory) {
            return this.localePath(`/shop/${encodeURIComponent(productCategory.shop_slug || productCategory.shop_name || productCategory.name)}`)
        },

        toFullName(product) {
            let category = this.productCategories.find(category => category.url === product.category)
            return category?.products.filter(product => product.included_in_shop).length > 1 ? `${category?.shop_name || category?.name} (${product.shop_name || product.name})` : `${category?.shop_name || category?.name}`
        },

        async translateProducts() {
            await Promise.all([this.$store.dispatch("api/countries/get"), this.$store.dispatch("api/languages/get")])
            if (this.$store.state.cart.currentLocale !== this.$localization.currentLanguage.url && this.$store.state.api.productCategories?.length) {
                this.$store.commit("cart/setCurrentLocale", this.$localization.currentLanguage.url)
                this.$store.commit("api/productCategories/set", [...this.$store.state.api.productCategories])
            }
            if (this.$store.state.cart.productCategoriesCacheLocale !== this.$localization.currentLanguage.url && this.$store.state.api.productCategoriesCache?.length) {
                this.$store.commit("cart/setProductCategoriesCacheLocale", this.$localization.currentLanguage.url)
                this.$store.commit("api/productCategoriesCache/set", [...this.$store.state.api.productCategoriesCache])
            }
        },
        calculateDeduction(currentPrice, couponCodeOverride = null, nthDelivery = 1) {
            const couponToUse = couponCodeOverride || this.coupon
            if (couponToUse) {
                let discount = couponToUse.discount_amount
                let type = couponToUse.type

                if (couponToUse.sequences && couponToUse.sequences.length > 0) {
                    let firstSequence = couponToUse.sequences.filter(s => s.order === nthDelivery)
                    if (!firstSequence) {
                        return { deduction: 0, price: Math.ceil(currentPrice), excess: 0 }
                    }
                    discount = firstSequence[0].discount_amount
                    type = firstSequence[0].type
                }
                if (type === "absolute") {
                    return { deduction: Math.min(currentPrice - 1, discount), price: Math.max(1, currentPrice - discount), excess: Math.max(0, discount - currentPrice) }
                } else if (type === "percentage") {
                    return {
                        deduction: Math.floor((currentPrice * discount) / 100),
                        price: Math.ceil(currentPrice * (100 - discount)) / 100,
                        excess: 0,
                    }
                }
            }
            return { deduction: 0, price: Math.ceil(currentPrice), excess: 0 }
        },
    },

    computed: {
        cartItems() {
            var products = []
            for (let key in this.cart) products.push(this.cart[key])
            return products
        },
        cartContent() {
            return Object.keys(this.$store.state.cart.items).length
        },
        cartPriceNumber() {
            var sum = 0
            for (let key in this.cart) sum += this.cart[key].quantity * this.getDiscountedPrice(this.cart[key].product)
            return sum
        },
        cartPrice() {
            return this.$helpers.format.currency(this.cartPriceNumber)
        },
        cartDeliveryPriceNumber() {
            var cartPrice = this.cartPriceNumber - this.cartCouponDiscountNumber
            for (let rule of this.deliveryRules) if ((rule.total_from <= cartPrice || !rule.total_from) && (rule.total_to > cartPrice || !rule.total_to) && rule.country === this.$localization.currentCountry?.url) return rule.price
            return 0
        },
        cartDeliveryPrice() {
            return this.$helpers.format.currency(this.cartDeliveryPriceNumber)
        },
        cartCouponDiscountNumber() {
            if (!this.cartCoupon) return 0
            return this.calculateDeduction(this.cartPriceNumber, this.cartCoupon).deduction
        },
        cartCouponDiscount() {
            return this.$helpers.format.currency(0 - this.cartCouponDiscountNumber)
        },
        cartTotal() {
            return this.$helpers.format.currency(this.cartPriceNumber - this.cartCouponDiscountNumber + this.cartDeliveryPriceNumber)
        },
        discountExcessNumber() {
            return this.calculateDeduction(this.cartPriceNumber, this.cartCoupon).excess
        },
        freeDeliveryPrice() {
            let freeRules = this.deliveryRules.filter(rule => (!rule.price || rule.price === 0) && rule.country === this.$localization.currentCountry?.url)
            if (!freeRules.length) return false
            return Math.max(...freeRules.map(rule => rule.total_from || 0))
        },
        productCategories() {
            if (!this.$store.state.api.productCategories?.length) return this.$store.state.api.productCategoriesCache
            return this.$store.state.api.productCategories
        },
        deliveryRules() {
            return this.$store.state.api.shippingPrices
        },
        familyMember() {
            if (!this.$store.getters["authentication/authenticated"]) return false
            return this.$store.state.api.recurringDeliveries?.[0]?.status !== "inactive"
        },
        shopDeliverySentence() {
            return this.productCategories.find(category => category.shop_delivery_sentence && Object.values(this.cart).some(cartProduct => cartProduct.product.category === category.url))?.shop_delivery_sentence
        },
        cartCoupon: {
            get() {
                return this.$store.state.cart.coupon
            },
            async set(val) {
                await this.$store.commit("cart/setCoupon", val)
            },
        },
    },

    async mounted() {
        this.$nuxt.$on("cartChange", () => this.getCart())
        this.$store.dispatch("api/productCategories/get").then(this.translateProducts).then(this.getCart)

        const promises = [this.$store.dispatch("api/productCategoriesCache/get").then(this.translateProducts), this.$store.dispatch("api/shippingPrices/get")]

        if (this.$store.getters["authentication/authenticated"]) this.$store.dispatch("api/recurringDeliveries/get")

        await Promise.all(promises)
    },

    watch: {
        currentLanguage() {
            this.translateProducts().then(this.getCart)
        },
    },
}
