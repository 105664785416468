<template>
    <div class="mainwrapper">
        <LayoutComponent>
            <nuxt />
        </LayoutComponent>
    </div>
</template>

<script lang="js">
    import LayoutComponent from "../components/LayoutComponent"
    import PollSubscriptionMixin from "~/components/mixins/PollSubscriptionMixin"
    import CustomerVisitMixin from "~/components/mixins/CustomerVisitMixin"

    export default {
        components: { LayoutComponent },
        mixins: [PollSubscriptionMixin, CustomerVisitMixin()],
        computed: {
            breakpointMenu() {
                return this.menu ? this.menu[this.$mq] : null
            },
        },
    }
</script>

<style lang="scss" scoped>
    .mainwrapper {
        background-color: var(--color-cream) !important;
    }
</style>

<style lang="scss">
    :root {
        --red: #fc4628;
    }

    body {
        overflow-y: auto;
        overflow-x: hidden;
        font-family: "GT-Walsheim";
    }

    .justify-between {
        justify-content: space-between;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-end {
        justify-content: end;
    }

    .p-0 {
        padding: 0;
    }

    .m-0 {
        margin: 0 !important;
    }

    .h-full {
        height: 100%;
    }
</style>
