import Vue from "vue"
import { getCookie } from "~/helpers/CookieHelper"

export default function ({ $axios, $helpers, $gtm }, inject) {
    const TiktokApiEvents = {}
    TiktokApiEvents.lead = () => {
        $axios
            .$post(`/tiktok-events/lead/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.shopLead = () => {
        $axios
            .$post(`/tiktok-events/shop_lead/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.addToCart = () => {
        $axios
            .$post(`/tiktok-events/add_to_cart/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.shopAddToCart = data => {
        $axios
            .$post(`/tiktok-events/shop_add_to_cart/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
                ...data,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.purchase = deliveryId => {
        $axios
            .$post(`/tiktok-events/purchase/`, {
                delivery_id: deliveryId,
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.shopPurchase = deliveryId => {
        $axios
            .$post(`/tiktok-events/shop_purchase/`, {
                delivery_id: deliveryId,
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.trialPurchase = deliveryId => {
        $axios
            .$post(`/tiktok-events/trial_purchase/`, {
                delivery_id: deliveryId,
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.insuranceLead = () => {
        $axios
            .$post(`/tiktok-events/insurance_lead/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.insuranceCoronaDirectLead = () => {
        $axios
            .$post(`/tiktok-events/insurance_corona_direct_lead/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }
    TiktokApiEvents.viewContent = () => {
        $axios
            .$post(`/tiktok-events/view_content/`, {
                ttclid: getCookie("ttclid"),
                url: window.location.href,
            })
            .then(data => {
                //
            })
    }

    inject("tiktokEventsApi", {
        events: TiktokApiEvents,
    })
}
