// Guard to ensure that the user has ever been subscribed.
// There is a possibility that a user is fully authenticated with a password,
// but still has never subscribed to a plan. This can happen for example when a user
// got to the checkout page but never completed the payment.

export default async function ({ app, redirect, store, $localization }) {
    await store.dispatch("api/countries/get")

    console.log($localization.currentCountry.short_name)
    if ($localization.currentCountry.short_name !== "BE") {
        return redirect({
            path: app.localePath("/signup"),
        })
    }
}
