<template>
    <div>
        <div class="cartActivator md:hidden">
            <nuxt-link :to="localePath({ path: '/shop/cart' })">
                <span class="position-relative">
                    <icon icon="mdi:cart" class="h-2 w-auto cursor-pointer text-cream" />
                    <div class="cartBadge" v-if="cartContent > 0" testid="eshop.cart_badge">{{ cartContent }}</div>
                </span>
            </nuxt-link>
        </div>
        <div class="hidden md:block">
            <Menu v-model="showCart" rightFull>
                <template v-slot:activator>
                    <div @click="() => (showCart = !showCart)" class="cartActivator" testid="eshop.cart_toggle">
                        <span class="position-relative">
                            <icon icon="mdi:cart" class="h-2 w-auto cursor-pointer text-cream" />
                            <div class="cartBadge" v-if="cartContent > 0">{{ cartContent }}</div>
                        </span>
                    </div>
                </template>
                <div class="shadow rounded bg-cream px-4 py-3 text-black w-[600px]">
                    <div class="flex items-center">
                        <div>{{ cartContent === 1 ? $t("1 product in the cart") : $t("{amount} products in the cart", { amount: cartContent }) }}</div>
                        <div class="ml-auto font-bold">{{ cartPrice }}</div>
                    </div>
                    <div class="border-b border-grey my-2"></div>
                    <CartList quantityTool light />
                    <div class="border-b border-grey my-2"></div>
                    <div class="flex justify-center">
                        <div
                            @click="
                                () => {
                                    $router.push(localePath({ path: '/shop/cart' }))
                                    showCart = false
                                }
                            "
                            class="cartAction"
                            testid="eshop.go_to_cart"
                        >
                            {{ $t("To cart") }}
                        </div>
                    </div>
                </div>
            </Menu>
        </div>
    </div>
</template>

<script>
    import ProductQuantity from "~/components/features/eshop/ProductQuantity.vue"
    import CartList from "~/components/features/eshop/CartList.vue"
    import ShopCartMixin from "~/components/mixins/ShopCartMixin"
    import Menu from "~/components/library/Menu.vue"
    export default {
        mixins: [ShopCartMixin],
        components: { ProductQuantity, CartList, Menu },
        data: () => ({
            showCart: false,
        }),
        mounted() {
            this.$nuxt.$on("openCart", e => {
                this.openCart()
            })
        },
        methods: {
            openCart() {
                this.showCart = false
                this.$nextTick(() => {
                    this.showCart = true
                })
            },
        },
    }
</script>

<style lang="scss">
    .cartMenu {
        @media (max-width: 960px) {
            position: fixed !important;
            max-width: unset !important;
            top: 100px !important;
            left: 12px !important;
            right: 12px !important;
            max-height: 60vh !important;
        }
    }
</style>

<style scoped lang="scss">
    .cartListWrapper {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .position-relative {
        position: relative;
    }

    .cartBadge {
        position: absolute;
        bottom: 50%;
        left: calc(50% + 2px);
        color: black;
        text-align: center;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        font-size: 10px;
        box-sizing: border-box;
        background-color: var(--color-green);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .v-menu__content {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
    }

    .cartPanel {
        width: 600px;
        padding: 2rem 4rem;
        background-color: var(--color-cream);
        border: none;
    }

    @media (max-width: 960px) {
        .cartPanel {
            width: 100%;
            padding: 1rem 2rem;
        }
    }

    .cartContent {
        text-align: left;
    }

    .cartPrice {
        text-align: right;
        font-weight: bold;
    }

    .cartDivider {
        border-top: 1px solid var(--color-grey);
        margin: 1rem 0;
    }

    .cartAction {
        box-sizing: border-box;
        cursor: pointer;
        color: black;
        background-color: var(--color-green);
        font-size: 18px;
        width: 80%;
        padding: 0.5rem;
        border-radius: 20px;
        text-align: center;
    }

    .delete {
        cursor: pointer;
        margin-left: 0.5rem;
    }
</style>
