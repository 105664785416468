<template>
    <img :style="{ width: width || '100%', height: height || '100%' }" style="fill: currentColor; vertical-align: middle" :src="src" v-on="$listeners" />
</template>

<script>
    export default {
        name: "IconComponent",
        props: {
            src: {
                type: String,
                required: true,
            },
            width: {
                type: String,
                default: null,
            },
            height: {
                type: String,
                default: null,
            },
        },
    }
</script>

<style scoped></style>
