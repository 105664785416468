<template>
    <button @click="authenticated ? logout() : login()" :class="['border border-solid rounded-full flex justify-center items-center leading-loose px-2', dark ? 'border-black text-black' : 'border-cream text-cream']">
        {{ authenticated ? $t("Log out") : $t("Sign in") }}
    </button>
</template>

<script>
    import ToggleAuthStateMixin from "../../mixins/ToggleAuthStateMixin"
    import { mapGetters } from "vuex"
    export default {
        mixins: [ToggleAuthStateMixin],
        props: {
            dark: Boolean,
        },
        computed: {
            ...mapGetters({
                authenticated: "authentication/authenticated",
            }),
        },
    }
</script>
