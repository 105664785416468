import createPersistedState from "~/node_modules/vuex-persistedstate"

export const state = () => ({})

export const mutations = {}

export const plugins = [
    createPersistedState({
        key: "just-russel",
        paths: ["authentication", "api.languageUpdatedByUser", "api.currentFlow"],
        storage: window.sessionStorage,
    }),
]
