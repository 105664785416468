<template>
    <div class="stepper flex" :class="{ '!mb-0': !steps.some(x => x.label) }">
        <div v-for="option in steps" :key="option.href" :class="['step flex', { grow: steps.indexOf(option) !== steps.length - 1, active: active(option), complete: complete(option) }]">
            <div @click="redirect(option)" :class="['content flex flex-col items-center', { 'cursor-pointer': canTravelTo(option) }]">
                <icon icon="mdi:check" class="icon text-cream font-bold rounded" />
                <span class="label h5 font-bold text-black text-center whitespace-nowrap">{{ option.label }}</span>
            </div>
            <div class="connector"></div>
            <div class="connector completion" :style="`width: calc(${progress}% - ${(progress / 100) * 24}px)`"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            step: Number,
            steps: Array,
            progress: {
                type: Number,
                default: 50,
            },
        },
        methods: {
            active(option) {
                return this.steps.indexOf(option) === this.step - 1
            },
            complete(option) {
                return this.steps.indexOf(option) < this.step - 1
            },
            canTravelTo(option) {
                return option.href && (this.steps.find(option => this.active(option))?.allowNavigateForward || this.complete(option) || this.active(option))
            },
            redirect(option) {
                if (this.canTravelTo(option)) this.$router.push(option.href)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .stepper {
        width: 100%;
        margin-bottom: 17px;
        box-sizing: border-box;
        .step {
            position: relative;
            &:not(:last-child) {
                flex-grow: 1;
                .connector {
                    margin-left: 24px;
                    position: absolute;
                    top: 12px;
                    border-top: 3px solid var(--color-light-grey);
                    width: 100%;
                    z-index: 1;
                }
            }
            .content {
                z-index: 2;
            }
            .icon {
                width: 24px;
                height: 24px;
                padding: 3px;
                background-color: var(--color-light-grey);
            }
            .label {
                position: absolute;
                bottom: -20px;
                @media (max-width: 960px) {
                    font-size: 10px !important;
                    line-height: 14px !important;
                }
            }
            &.complete:not(.active) {
                .icon {
                    background-color: var(--color-green);
                }
                .connector {
                    border-color: var(--color-green);
                }
                .completion {
                    display: none;
                }
            }
            &.active {
                .icon {
                    background-color: var(--color-green);
                }
                .completion {
                    border-color: var(--color-green);
                }
            }
        }
    }
</style>
