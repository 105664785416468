<template>
    <LayoutComponent :key="$localization.locale" countryPopup>
        <div :class="['applicationContainer flex flex-col h4 bg-cream']">
            <slot />
        </div>
    </LayoutComponent>
</template>

<script>
    import PollSubscriptionMixin from "~/components/mixins/PollSubscriptionMixin"

    import LayoutComponent from "~/components/LayoutComponent.vue"

    export default {
        components: { LayoutComponent },
        mixins: [PollSubscriptionMixin],
        mounted() {
            this.scrollTop()
            setTimeout(this.scrollTop, 100)
        },
        watch: {
            "$route.path"() {
                setTimeout(this.scrollTop, 100)
            },
        },
        methods: {
            scrollTop() {
                window.scrollTo({ top: 0 })
            },
        },
    }
</script>

<style lang="scss">
    .all-content-container {
        z-index: unset !important;
    }
    .applicationContainer {
        min-height: 100vh;
    }
</style>
