<template>
    <div>
        <div class="mb-[117px]"></div>
        <div class="fixed top-0 left-0 right-0 shadow z-10">
            <HeaderBanner />
            <section class="largest bg-red text-cream !py-05">
                <div class="hidden lg:flex items-center">
                    <a :href="menu['Home']" class="px-2 h-[40px]">
                        <img loading="lazy" src="/logo_white.svg" class="h-full" />
                    </a>
                    <a :href="menu['Home']" class="p-2 ml-auto">{{ $t("Return to website") }}</a>
                    <LanguageSelector :top="false" :block="false" buttonStyles="hover:bg-cream hover:text-black" />
                    <LoginButton class="ml-2" />
                    <div v-if="!$route.path.includes('/shop/delivery')" class="ml-3 flex justify-center items-center">
                        <Cart />
                    </div>
                </div>
                <div class="lg:hidden flex items-center">
                    <div class="w-full flex">
                        <button class="flex items-center md:ml-3" @click="drawer = !drawer">
                            <icon icon="mdi:menu" class="h-3 w-auto mr-05" />
                            <div class="py-2">{{ $t("Menu") }}</div>
                        </button>
                    </div>
                    <div class="w-full flex justify-center">
                        <a :href="menu['Home']" class="h-[40px]">
                            <img loading="lazy" src="/logo_white.svg" class="h-full" />
                        </a>
                    </div>
                    <div class="w-full flex justify-end">
                        <Cart v-if="!$route.path.includes('/shop/delivery')" />
                    </div>
                </div>
            </section>
        </div>
        <Drawer v-model="drawer">
            <div class="bg-cream h-full w-full p-2 flex flex-col">
                <div class="flex mb-1">
                    <div class="cursor-pointer" @click="drawer = false">
                        <icon icon="mdi:arrow-left" class="h-3 w-auto" />
                    </div>
                </div>
                <a class="py-1 text-red text-center border-b border-b-light-grey hover:bg-light-grey" :href="menu['Home']">{{ $t("Return to website") }}</a>
                <LanguageSelector class="mt-2" :top="false" buttonStyles="hover:bg-red hover:text-white" />
                <LoginButton class="mt-2" dark />
            </div>
        </Drawer>
    </div>
</template>

<script>
    import Cart from "~/components/features/eshop/Cart.vue"
    import Drawer from "~/components/library/Drawer.vue"
    import HeaderBanner from "~/components/features/eshop/HeaderBanner.vue"
    import IconComponent from "~/components/icons/IconComponent"
    import LanguageSelector from "~/components/features/language/LanguageSelector.vue"
    import LoginButton from "~/components/features/authentication/LoginButton.vue"
    import Menu from "~/components/library/Menu.vue"
    import WordpressLinks from "~/components/mixins/WordpressLinks.js"

    import PollSubscriptionMixin from "~/components/mixins/PollSubscriptionMixin"

    export default {
        name: "ShopNavbarWithCart",
        components: {
            Cart,
            Drawer,
            HeaderBanner,
            IconComponent,
            LanguageSelector,
            LoginButton,
            Menu,
        },
        mixins: [PollSubscriptionMixin, WordpressLinks],
        data: () => ({
            menu1: false,
            menu2: false,
            drawer: false,
        }),
    }
</script>
