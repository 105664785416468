<template>
    <div ref="card" class="jr_card" :style="style" :class="`bg_${bg}`">
        <div class="jr_card__header">
            <slot name="header">
                <div :class="['flex', 'flex-wrap', '!grow', '!shrink', '!basis-auto', '-mx-1']" v-if="header">
                    <div :class="['p-1', 'grow', '!basis-0', '!max-w-full', 'jr_card__header']">
                        <p>{{ header }}</p>
                    </div>
                </div>
            </slot>
        </div>

        <slot></slot>

        <div class="jr_card__actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Card",
        props: {
            bg: {
                type: String,
                default: "primary",
            },
            color: {
                type: String,
                default: "black",
            },
            header: {
                type: String,
                required: false,
            },
            noPadding: {
                type: Boolean,
                default: false,
            },
            perfectRound: {
                type: Boolean,
                default: false,
            },
            noRadius: {
                type: Boolean,
                default: false,
            },
            borderColor: {
                type: String,
                default: "transparent",
            },
            radius: {
                type: String,
                default: "31px",
            },
        },
        computed: {
            backgroundColor() {
                return Object.keys(this.$colors).includes(this.bg) ? this.$colors[this.bg] : this.bg
            },
            textColor() {
                return Object.keys(this.$colors).includes(this.color) ? this.$colors[this.color] : this.color
            },
            outlineColor() {
                return Object.keys(this.$colors).includes(this.borderColor) ? this.$colors[this.borderColor] : this.borderColor
            },
            style() {
                let style = {
                    "background-color": this.backgroundColor,
                    color: this.textColor,
                    "box-shadow": this.borderColor ? `0px 0px 0px 4px ${this.outlineColor}` : "auto",
                    "border-radius": this.noRadius ? "0 !important" : this.perfectRound ? `${this.height / 2}px` : this.radius,
                }
                if (this.noPadding) {
                    style["padding"] = "0 !important"
                }
                return style
            },
        },
        mounted() {
            if (this.perfectRound && this.$refs.card) {
                this.height = this.$refs.card.clientHeight
            }
        },
        data() {
            return {
                height: 0,
            }
        },
    }
</script>

<style lang="scss">
    .jr_card {
        overflow: hidden;
        padding: 15px 31px;
        &__header {
            margin-left: 0;
            margin-right: 0;
            position: relative;
            /*font-size: 25px;*/
            font-family: GT-Walsheim;
            text-align: center;
            p {
                margin-bottom: 0;
            }
            * {
                font-weight: bold !important;
            }
        }
        &__back_button {
            position: absolute;
            left: 0;
            top: 12px;
        }
        > * {
            background-color: inherit;
            color: inherit;
        }
        a {
            text-decoration: underline;
        }
    }
</style>
