<template>
    <BaseLayout>
        <Header wordpressLogoLink />
        <section v-if="error.statusCode === 503">
            <h1 class="my-2">{{ $t("Maintenance mode") }}</h1>
            <p class="text-center">{{ $t("The system is under maintenance, most likely because we are upgrading it.") }}</p>
            <p class="text-center mb-2">{{ $t("In general, this is a matter of minutes. Please give us some time and then try reloading the page again.") }}</p>
            <div class="flex justify-center">
                <Button large @click="reloadPage">{{ $t("Reload page") }}</Button>
            </div>
        </section>
        <section v-else>
            <h1 class="mb-3 text-center">{{ $t("Oops, something went wrong!") }}</h1>
            <div v-if="error.isApi" class="text-center text-grey">{{ $t("Error code: {code}", { code: error.statusCode }) }}</div>
            <div class="mb-3 text-center text-grey">{{ $t("Error message: {message}", { message: error.message }) }}</div>
            <div v-if="error.statusCode === 404" class="mb-4">
                <div class="mb-2 font-bold text-center md:text-left">
                    <b>{{ $t("We cannot find the page you are looking for, sorry! Could it be that you:") }}</b>
                </div>
                <ul class="mb-2">
                    <li>{{ $t("Made a typo when typing the address?") }}</li>
                    <li>{{ $t("The link you clicked on is expired?") }}</li>
                </ul>
                <div class="mb-2 font-bold text-center md:text-left">
                    <b>{{ $t("Instead, here are some helpful links:") }}</b>
                </div>
                <div class="mb-4">
                    <div>
                        <a class="underline cursor-pointer text-inherit" :href="menu['Home']">{{ $t("Homepage") }}</a>
                    </div>
                    <div>
                        <a class="underline cursor-pointer text-inherit" :href="menu['Tailor-made dog food']">{{ $t("Tailor-made dog food") }}</a>
                    </div>
                    <div>
                        <a class="underline cursor-pointer text-inherit" :href="menu['Tailor-made cat food']">{{ $t("Tailor-made cat food") }}</a>
                    </div>
                    <div>
                        <nuxt-link class="underline cursor-pointer text-inherit" :to="localePath('/signin')">{{ $t("My account") }}</nuxt-link>
                    </div>
                    <div>
                        <a class="underline cursor-pointer text-inherit" :href="menu['Help']">{{ $t("Help") }}</a>
                    </div>
                </div>
            </div>
            <div v-else class="mb-4">
                {{ $t("An error has occurred! We are working on our website every day and we are trying to solve this problem as soon as possible.") }}
            </div>
            <div class="bg-meat rounded p-3 mb-3">
                <Support />
            </div>
            <div :class="['flex', 'flex-wrap', '!grow', '!shrink', '!basis-auto', '-mx-1']">
                <div :class="['p-1', 'md:!basis-6/12', 'md:!max-w-[50%]', '!basis-full', '!max-w-full', 'mb-2']">
                    <Button wide @click="gotoHome">{{ $t("Homepage") }}</Button>
                </div>
                <div :class="['p-1', 'md:!basis-6/12', 'md:!max-w-[50%]', '!basis-full', '!max-w-full']">
                    <Button v-if="$store.state.authentication.authenticated" wide @click="logout">{{ $t("Log out") }}</Button>
                    <Button v-else wide @click="login(true)">{{ $t("Sign in") }}</Button>
                </div>
            </div>
        </section>
    </BaseLayout>
</template>

<script lang="js">
    import BaseLayout from "~/components/library/BaseLayout.vue"
    import Header from "~/components/library/Header.vue"
    import Support from "~/components/library/Support.vue"
    import Button from "~/components/library/Button.vue"
    import ToggleAuthStateMixin from "~/components/mixins/ToggleAuthStateMixin.js"
    import WordpressLinks from "~/components/mixins/WordpressLinks.js"
    import CustomerVisitMixin from "~/components/mixins/CustomerVisitMixin"
    export default {
        components: { BaseLayout, Header, Support, Button },
        mixins: [ToggleAuthStateMixin, WordpressLinks, CustomerVisitMixin()],
        props: ["error"],

        mounted() {
            console.error(this.error)
        },

        methods: {
            gotoHome() {
                window.location = this.menu["Home"]
            },
            reloadPage() {
                window.location.reload()
            },
        },
    }
</script>
