<template>
    <div class="flex items-center gap-3">
        <div v-if="$localization.currentCountry" :class="['flex flex-col justify-center w-full', { 'items-center': !image }]">
            <slot>
                <div v-if="image">
                    <h2 class="mb-2">{{ $t("Need help?") }}</h2>
                </div>
                <div :class="{ 'text-center': !image }">
                    {{ $t("If you have an urgent question, be sure to contact our customer service team. You can call us any weekday between {start} and {end}.", { start, end }) }}
                </div>
            </slot>
            <a :href="zendeskLink" target="_blank" class="mt-2">
                <Button large>{{ $t("Get in touch") }}</Button>
            </a>
            <slot name="bottom"></slot>
        </div>
        <div v-if="image" class="w-full">
            <img src="/jr-customer-service-team.jpg" class="w-full" />
        </div>
    </div>
</template>

<script>
    import Button from "./Button.vue"
    export default {
        props: {
            image: Boolean,
        },
        components: { Button },
        computed: {
            zendeskLink() {
                return this.$localization?.currentLanguage?.zendesk_link
            },
            start() {
                return this.$localization?.currentCountry?.working_hours_start?.slice(0, 5)
            },
            end() {
                return this.$localization?.currentCountry?.working_hours_end?.slice(0, 5)
            },
        },
    }
</script>
