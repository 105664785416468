<template>
    <div :class="{ cartItems: true, light: light }">
        <div v-for="item in cartItems" :key="item.product.url">
            <div v-if="item !== firstItem && divider" class="divider"></div>
            <div class="flex flex-wrap !grow !shrink !basis-auto -mx-1 cartProduct" testid="eshop.cart_product">
                <div class="p-1 w-auto !basis-auto !max-w-full cartProductWrapper">
                    <img :src="item.product.image" :alt="item.product.shop_name || item.product.name" class="cartProductImage" loading="lazy" />
                </div>
                <div class="p-1 grow basis-0 max-w-full">
                    <div class="flex flex-col justify-center h-full">
                        <div class="flex justify-between flex-wrap">
                            <div class="cartProductTitle">
                                <span v-if="!quantityTool" class="redColor">{{ item.quantity }}x </span>
                                <span v-html="item.titleBoldHtml"></span>
                            </div>
                            <div>{{ $helpers.format.currency(getDiscountedPrice(item.product) * item.quantity) }}</div>
                        </div>
                        <div class="cartProductPrice">
                            <span>{{ $t("Price per unit :") }}</span>
                            <s v-if="getDiscountedPrice(item.product) !== item.product.shop_price">{{ $helpers.format.currency(item.product.shop_price) }}</s>
                            <span>{{ $helpers.format.currency(getDiscountedPrice(item.product)) }}</span>
                        </div>
                        <div v-if="quantityTool" class="flex mt-1">
                            <ProductQuantity small :value="item.quantity" @input="quantity => changeQuantity(item.product, quantity)" @zero="remove(item.product)" />
                            <div @click="remove(item.product)" testid="eshop.remove_product">
                                <icon icon="mdi:trash-can-outline" class="delete h-full h3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductQuantity from "~/components/features/eshop/ProductQuantity.vue"
    import ShopCartMixin from "~/components/mixins/ShopCartMixin"
    export default {
        name: "Cart",
        components: { ProductQuantity },
        mixins: [ShopCartMixin],
        props: {
            light: Boolean,
            divider: Boolean,
            quantityTool: Boolean,
        },
        computed: {
            firstItem() {
                for (let key in this.cartItems) return this.cartItems[key]
            },
        },
        methods: {},
    }
</script>

<style scoped lang="scss">
    .cartProductImage {
        width: 80px;
        height: 80px;
        background-color: white;
        border-radius: 5px;
        border: 2px solid var(--color-red);
        padding: 5px;
        object-position: center;
        object-fit: contain;
    }
    .cartProductWrapper {
        line-height: 0;
    }
    .light .cartProductImage {
        background-color: var(--color-meat);
        border: none;
    }
    .divider {
        border-top: 1px solid var(--color-grey);
        margin-bottom: 0.5rem;
    }
    .redColor {
        color: var(--color-red);
    }
    .delete {
        cursor: pointer;
        margin-left: 0.5rem;
    }
</style>
