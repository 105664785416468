export const getAllCookies = () => {
    const cookieString = document.cookie
    const allCookies = {}
    cookieString.split("; ").forEach(string => {
        const key = string.split("=")[0]
        allCookies[key] = string.split("=")[1]
    })
    return allCookies
}

export const cookieExists = name => {
    const allCookies = getAllCookies()
    return Object.keys(allCookies).includes(name)
}

export const getCookie = name => {
    if (cookieExists(name)) {
        const allCookies = getAllCookies()
        return allCookies[name]
    }
    return null
}

export const cookieExistsRegex = regex => {
    const allCookies = getAllCookies()
    return (
        Object.keys(allCookies).filter(key => {
            return regex.test(key)
        }).length > 0
    )
}

export const getCookiesRegex = regex => {
    const allCookies = getAllCookies()
    return Object.keys(allCookies).filter(key => {
        return regex.test(key)
    })
}

export const setCookie = (name, value) => {
    const allCookies = getAllCookies()
    allCookies[name] = value
    let cookieString = ""
    Object.keys(allCookies).forEach(key => {
        cookieString += `${key}=${allCookies[key]}; `
    })
    document.cookie = cookieString
}
