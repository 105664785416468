import Vue from "vue"

export default (ctx, inject) => {
    inject(
        "mq",
        new Vue({
            data: () => ({
                size: window.innerWidth,
            }),
            created() {
                window.addEventListener("resize", this.resize)
            },
            destroyed() {
                window.removeEventListener("resize", this.resize)
            },
            methods: {
                resize() {
                    this.size = window.innerWidth
                },
            },
            computed: {
                mobile() {
                    return this.size < 768
                },
                tablet() {
                    return this.size < 1280
                },
            },
        }),
    )
}
