export default function ({ store, $gtm, $config, $facebookConversionApi, $tiktokEventsApi, $affiliateNetworks, $helpers }, inject) {
    const TrackingEvents = {}

    TrackingEvents.lead = async () => {
        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = await store.dispatch("api/customer/get")
        const customerHashId = $helpers.extractId(customer.url)
        if (window.localStorage.getItem(`TrackingEvent:Lead_${customerHashId}`) === "fired") {
            return
        }

        // Validate Affiliate Network
        const affiliateNetwork = $affiliateNetworks.ifCookieExists((exists, name) => {
            return exists ? name : null
        })

        // Push conversion
        window.localStorage.setItem(`TrackingEvent:Lead_${customerHashId}`, "fired")
        $gtm.push({
            event: "TrackingEvent:Lead",
            env: $config.env,
            tracking_event_data: {
                external_id: customerHashId,
                affiliate_network: affiliateNetwork,
                user_email: user.email,
                user_firstname: customer.firstname,
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.lead()
    }

    TrackingEvents.shopLead = () => {
        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = store.state.api.customer
        const customerHashId = $helpers.extractId(customer.url)
        if (window.localStorage.getItem(`TrackingEvent:ShopLead_${customerHashId}`) === "fired") {
            return
        }
        // Push conversion
        window.localStorage.setItem(`TrackingEvent:ShopLead_${customerHashId}`, "fired")
        $gtm.push({
            event: "TrackingEvent:ShopLead",
            env: $config.env,
            tracking_event_data: {
                external_id: customerHashId,
                user_email: user.email,
                user_firstname: customer.firstname,
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.shopLead()
    }

    TrackingEvents.insuranceCoronaDirectLead = () => {
        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = store.state.api.customer
        const customerHashId = $helpers.extractId(customer.url)
        if (window.localStorage.getItem(`TrackingEvent:InsuranceCoronaDirectLead_${customerHashId}`) === "fired") {
            return
        }

        // Browser conversions
        window.localStorage.setItem(`TrackingEvent:InsuranceCoronaDirectLead_${customerHashId}`, "fired")

        $gtm.push({
            env: $config.env,
            event: "TrackingEvent:InsuranceCoronaDirectLead",
            tracking_event_data: {
                external_id: customerHashId,
                user_email: user.email,
                user_firstname: customer.firstname || user?.firstname,
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
    }

    TrackingEvents.insuranceLead = () => {
        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = store.state.api.customer
        const customerHashId = $helpers.extractId(customer.url)
        if (window.localStorage.getItem(`TrackingEvent:InsuranceLead_${customerHashId}`) === "fired") {
            return
        }

        // Browser conversions
        window.localStorage.setItem(`TrackingEvent:InsuranceLead_${customerHashId}`, "fired")

        $gtm.push({
            env: $config.env,
            event: "TrackingEvent:InsuranceLead",
            tracking_event_data: {
                external_id: customerHashId,
                user_email: user.email,
                user_firstname: customer.firstname,
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.insuranceLead()
    }

    TrackingEvents.addToCart = () => {
        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = store.state.api.customer
        const customerHashId = $helpers.extractId(customer.url)
        if (window.localStorage.getItem(`TrackingEvent:AddToCart_${customerHashId}`) === "fired") {
            return
        }

        // Push conversion
        window.localStorage.setItem(`TrackingEvent:AddToCart_${customerHashId}`, "fired")

        $gtm.push({
            event: "TrackingEvent:AddToCart",
            env: $config.env,
            tracking_event_data: {
                external_id: customerHashId,
                user_email: user.email,
                user_firstname: customer.firstname,
                user_phone: customer?.phone,
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.addToCart()
    }

    TrackingEvents.shopAddToCart = (product, quantity) => {
        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = store.state.api.customer
        const cart = store.state.cart

        // Push conversion
        const trackingEventData = {
            external_id: `${cart.id}__${product.id}__${new Date().getTime()}`,
            product_id: product?.id,
            quantity,
            price: product?.shop_price / 100,
            value: (product?.shop_price * quantity) / 100,
            user_email: user?.email,
            user_firstname: customer?.firstname,
            client_user_agent: navigator.userAgent,
        }
        $gtm.push({
            event: "TrackingEvent:ShopAddToCart",
            env: $config.env,
            tracking_event_data: {
                external_id: `${cart.id}__${product.id}__${new Date().getTime()}`,
                product_id: product?.id,
                quantity,
                price: product?.shop_price / 100,
                value: (product?.shop_price * quantity) / 100,
                user_email: user?.email,
                user_firstname: customer?.firstname,
                user_phone: customer?.phone,
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.shopAddToCart(trackingEventData)
    }

    TrackingEvents.showContent = ({ type, name }) => {
        const content = { type, name }

        // Prepare conversion
        const user = store.state.authentication.currentUser
        const customer = store.state.api.customer
        const customerHashId = $helpers.extractId(customer.url)
        if (window.localStorage.getItem(`TrackingEvent:ShowContent_${type}_${name}_${customerHashId}`) === "fired") {
            return
        }

        // Push conversion
        window.localStorage.setItem(`TrackingEvent:ShowContent_${type}_${name}_${customerHashId}`, "fired")

        $gtm.push({
            event: "TrackingEvent:ShowContent",
            env: $config.env,
            tracking_event_data: {
                external_id: customerHashId,
                user_email: user.email,
                user_firstname: customer.firstname,
                client_user_agent: navigator.userAgent,
                content,
            },
        })

        // Server-to-server conversions
        // None yet
    }

    TrackingEvents.purchase = (user, customer, delivery) => {
        // Prepare conversion
        const deliveryHashId = $helpers.extractId(delivery.url)
        if (window.localStorage.getItem(`TrackingEvent:Purchase_${deliveryHashId}`) === "fired") {
            return
        }

        // Validate Affiliate Network
        const affiliateNetwork = $affiliateNetworks.validateDelivery(customer, delivery, (found, name) => {
            return found ? name : null
        })
        // Push conversion
        window.localStorage.setItem(`TrackingEvent:Purchase_${deliveryHashId}`, "fired")

        $gtm.push({
            event: "TrackingEvent:Purchase",
            env: $config.env,
            tracking_event_data: {
                external_id: deliveryHashId,
                amount: delivery.price_excl_vat / 100,
                value: delivery.price_excl_vat / 100,
                currency: "EUR",
                voucher: delivery.couponcodes ? delivery.couponcodes.join(",") : "",
                affiliate_network: affiliateNetwork,
                user_email: user?.email,
                user_firstname: customer?.firstname,
                user_lastname: customer?.lastname,
                user_phone: customer?.phone,
                user_city: delivery.delivery_address.city,
                user_postal_code: delivery.delivery_address.postal_code,
                user_country: delivery.delivery_address.country.toLowerCase(),
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.purchase(deliveryHashId)
    }

    TrackingEvents.shopPurchase = (user, customer, delivery) => {
        // Prepare conversion
        const deliveryHashId = $helpers.extractId(delivery.url)
        if (window.localStorage.getItem(`TrackingEvent:ShopPurchase_${deliveryHashId}`) === "fired") {
            return
        }

        // Validate Affiliate Network
        const affiliateNetwork = $affiliateNetworks.validateDelivery(customer, delivery, (found, name) => {
            return found ? name : null
        })

        // Browser conversions
        window.localStorage.setItem(`TrackingEvent:ShopPurchase_${deliveryHashId}`, "fired")

        $gtm.push({
            event: "TrackingEvent:ShopPurchase",
            env: $config.env,
            tracking_event_data: {
                external_id: deliveryHashId,
                amount: delivery.price_excl_vat / 100,
                value: delivery.price_excl_vat / 100,
                currency: "EUR",
                voucher: delivery.couponcodes ? delivery.couponcodes.join(",") : "",
                affiliate_network: affiliateNetwork,
                user_email: user.email,
                user_firstname: customer.firstname,
                user_lastname: customer.lastname,
                user_phone: customer.phone,
                user_city: delivery.delivery_address.city,
                user_postal_code: delivery.delivery_address.postal_code,
                user_country: delivery.delivery_address.country.toLowerCase(),
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.shopPurchase(deliveryHashId)
    }

    TrackingEvents.trialPurchase = (user, customer, delivery) => {
        // Prepare conversion
        const deliveryHashId = $helpers.extractId(delivery.url)
        if (window.localStorage.getItem(`TrackingEvent:TrialPurchase${deliveryHashId}`) === "fired") {
            return
        }

        // Validate Affiliate Network
        const affiliateNetwork = $affiliateNetworks.validateDelivery(customer, delivery, (found, name) => {
            return found ? name : null
        })

        // Browser conversions
        window.localStorage.setItem(`TrackingEvent:TrialPurchase${deliveryHashId}`, "fired")

        $gtm.push({
            event: "TrackingEvent:TrialPurchase",
            env: $config.env,
            tracking_event_data: {
                external_id: deliveryHashId,
                amount: delivery.price_excl_vat / 100,
                value: delivery.price_excl_vat / 100,
                currency: "EUR",
                voucher: delivery.couponcodes ? delivery.couponcodes.join(",") : "",
                affiliate_network: affiliateNetwork,
                user_email: user.email,
                user_firstname: customer.firstname,
                user_lastname: customer.lastname,
                user_phone: customer.phone,
                user_city: delivery.delivery_address.city,
                user_postal_code: delivery.delivery_address.postal_code,
                user_country: delivery.delivery_address.country.toLowerCase(),
                client_user_agent: navigator.userAgent,
            },
        })

        // Server-to-server conversions
        $tiktokEventsApi.events.trialPurchase(deliveryHashId)
    }

    inject("tracking", {
        events: TrackingEvents,
    })
}
