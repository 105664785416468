import firebase from "firebase/app"
import "firebase/auth"

import { timeAsyncFunction, timeFunction } from "~/utils/console"

export class AuthenticationError extends Error {
    constructor(message, providers) {
        super()
        this.message = message
        this.providers = providers
    }

    translated_message(ctx) {
        return ctx.$t(this.message)
    }
}
export class UserAlreadyExistsError extends AuthenticationError {
    translated_message(ctx) {
        return ctx.$t("This user already exists. Click {signin} to log in.", {
            signin: `<a class="underline cursor-pointer text-inherit text-red" href="/signin">${ctx.$t("here")}</a>`,
        })
    }
}
export class UserNotFoundError extends AuthenticationError {
    translated_message(ctx) {
        return ctx.$t("The email address you entered is not registered. Please try another email, create a new account {here} or contact our customer service.", {
            here: `<a class="underline cursor-pointer text-inherit text-red" href="/signup">${ctx.$t("here")}</a>`,
        })
    }
}
export class InvalidEmailError extends AuthenticationError {}
export class UserDisabledError extends AuthenticationError {}
export class InvalidActionCodeError extends AuthenticationError {}
export class WrongPasswordError extends AuthenticationError {
    translated_message(ctx) {
        return ctx.$t("The login credentials for this user are incorrect. Try logging in with Google, Facebook, or Apple, or click {resetpassword} to reset your password.", {
            resetpassword: `<a class="underline cursor-pointer text-inherit text-red" href="/forgot-password">${ctx.$t("here")}</a>`,
        })
    }
}
export class TooManyAttemptsError extends AuthenticationError {}
export class LoginCancelledByUser extends AuthenticationError {}

export class RequestQuotaExceeded extends AuthenticationError {}

function translateFirebaseError(err) {
    const providers = []

    if (err.code == undefined) {
        return err
    }

    switch (err.code) {
        case "auth/user-not-found":
            return new UserNotFoundError(err.message, providers)
        case "auth/invalid-email":
            return new InvalidEmailError(err.message, providers)
        case "auth/user-disabled":
            return new UserDisabledError(err.message, providers)
        case "auth/wrong-password":
            return new WrongPasswordError(err.message, providers)
        case "auth/email-already-in-use":
            return new UserAlreadyExistsError(err.message, providers)
        case "auth/too-many-requests":
            return new TooManyAttemptsError(err.message, providers)
        case "auth/popup-closed-by-user":
            return new LoginCancelledByUser(err.message, providers)
        case "auth/quota-exceeded":
            return new RequestQuotaExceeded(err.message, providers)
        case "auth/invalid-action-code":
            return new InvalidActionCodeError(err.message, providers)
        default:
            console.error(`unknown Firebase error code: ${err.code}`)
            return new AuthenticationError(err.message, providers)
    }
}

export default function (context, inject) {
    // signup callback
    const onSignup = timeAsyncFunction("authentication:onSignup", async (customerPayload, waitForCustomerUpdate) => {
        if (window.sessionStorage.getItem("authenticated")) {
            return
        }

        // Fetch the animals to create the customer and prepare for the onboarding flow.
        // This prevents multiple requests from attempting to generate the same customer and
        // speeds up subsequent requests.
        await context.store.dispatch("api/animals/get")

        const customerPromise = context.store.dispatch("api/customer/get").then(async customer => {
            const attributes = {
                url: customer.url,
                leadsource: context.$helpers.leadsource.getLeadSource(),
                newsletter: context.store.state.api.customer.newsletter,
                prefered_country: context.$localization.currentCountry.url,
                prefered_language: context.$localization.currentLanguage.url,
                current_store_id: "justrussel",
                firstname: context.store.state.api.customer.firstname || undefined,
                lastname: context.store.state.api.customer.lastname || undefined,
            }

            await context.store.dispatch("api/customer/patch", { ...attributes, ...customerPayload })
        })

        // Perform the update and wait for it if necessary
        if (waitForCustomerUpdate) {
            console.debug("waiting for customer fetch & update")
            await customerPromise
        }

        // Google Tag Manager
        context.$gtm.push({
            event: "JR_signed-up",
            env: context.$config.env,
        })
        context.$helpers.cookie.setCookie("jr_signed-up", 1)
    })

    // signin callback, runs on signin including page loads with an already
    // authenticated user.
    const onSignin = timeAsyncFunction("authentication:onSignin", async (customerPayload, waitForCustomerUpdate) => {
        if (sessionStorage.getItem("authenticated")) {
            return
        }

        console.debug("user logged in")

        const customerPromise = context.store.dispatch("api/customer/get").then(async customer => {
            const attributes = {
                url: customer.url,
                leadsource: context.$helpers.leadsource.getLeadSource(),
                prefered_language: context.$localization.currentLanguage.url,
                ...customerPayload,
            }

            await context.store.dispatch("api/customer/patch", attributes)
        })

        if (waitForCustomerUpdate) {
            await customerPromise
        }
    })

    // Attempt sign in with email & password.
    // allowRegister: If true and the email is not registered, register it.
    // waitForCustomerUpdate: If true: Wait for the customer patch request to finish.
    // customerPayload: custom attributes to set on the customer after signup.
    const signInWithEmailAndPassword = async ({ email, password, allowRegister = false, waitForCustomerUpdate = true, customerAttributes = {} }) => {
        console.debug("signing in with email & password")
        try {
            await context.$firebaseAuth.signInWithEmailAndPassword(email, password)
            context.store.commit("authentication/setIsAnonymous", password === "anonymous")
            await onSignin(customerAttributes, waitForCustomerUpdate)
        } catch (err) {
            const errorInstance = translateFirebaseError(err)

            // If user was not found and registration is allowed, register.
            if (errorInstance instanceof UserNotFoundError && allowRegister) {
                return await signUpWithEmail({ email, password, customerAttributes })
                // If wrong password and user has no password credentials, throw NoPasswordLogin.
            }
            throw errorInstance
        }
    }

    const signUpWithEmail = timeAsyncFunction("authentication:signUpWithEmail", async ({ email, password = "anonymous", customerAttributes = {} }) => {
        console.debug("signing up with email & password")
        try {
            // Attempt signup
            const { user } = await context.$firebaseAuth.createUserWithEmailAndPassword(email, password)
            context.store.commit("authentication/setIsAnonymous", password === "anonymous")
            await onSignup(customerAttributes, false)
        } catch (err) {
            const registrationError = translateFirebaseError(err)

            if (registrationError instanceof UserAlreadyExistsError) {
                return await signinAnonymousUser({ email, customerAttributes, waitForCustomerUpdate: false })
            }
            throw registrationError
        }
    })

    const signinAnonymousUser = timeAsyncFunction("authentication:signinAnonymousUser", async ({ email, customerAttributes = {}, waitForCustomerUpdate = false }) => {
        try {
            await context.$firebaseAuth.signInWithEmailAndPassword(email, "anonymous")
            context.store.commit("authentication/setIsAnonymous", true)
            await onSignup(customerAttributes, waitForCustomerUpdate)
        } catch (err) {
            const loginError = translateFirebaseError(err)

            if (loginError instanceof WrongPasswordError) {
                throw new UserAlreadyExistsError(loginError.message, loginError.providers)
            }
            throw loginError
        }
    })

    // Promote an anonymous user to a user with a custom password.
    const promoteAnonymousUser = timeAsyncFunction("authentication:promoteAnonymousUser", async password => {
        console.debug("promoting anonymous user")
        try {
            await changePassword("anonymous", password)
            context.store.commit("authentication/setIsAnonymous", false)
        } catch (err) {
            // If 'anonymous' is not the correct password, then the user is NOT anonymous
            // and we continue without throwing an error.
            // This should not happen, but we handle it just in case.
            if (err instanceof WrongPasswordError) {
                console.debug("invalid password, letting user continue")
                context.store.commit("authentication/setIsAnonymous", false)
            } else {
                throw err
            }
        }
    })

    const linkEmailPasswordCredentials = timeAsyncFunction("authentication:linkEmailPasswordCredentials", async (email, password) => {
        console.debug("linking social provider account with email and password")
        const user = context.$firebaseAuth.currentUser

        await reAuthenticate(null)
        await user.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email, password))
        // TODO: this next line is not necessary, as the user object is already updated through the
        // onAuthStateChanged listener in the FirebaseAuth plugin
        context.store.commit("authentication/setUser", user)
    })

    const unlinkProvider = timeAsyncFunction("authentication:unlinkProvider", async provider => {
        console.debug(`unlinking provider ${provider} from account`)
        const user = context.$firebaseAuth.currentUser
        await user.unlink(provider)
        // TODO: this next line is not necessary, as the user object is already updated through the
        // onAuthStateChanged listener in the FirebaseAuth plugin
        context.store.commit("authentication/setUser", user)
    })

    const awaitSocialAuth = timeAsyncFunction("authentication:awaitSocialAuth", async (socialProvider, registered) => {
        try {
            const { user } = await context.$firebaseAuth.signInWithPopup(socialProvider)
            let firstname = ""
            let lastname = ""
            if (user.displayName != null) {
                const nameParts = user.displayName.split(" ", 2)
                firstname = nameParts[0]
                if (nameParts.length > 1) {
                    lastname = nameParts[1]
                }
            }

            let attributes = {
                firstname: firstname || undefined,
                lastname: lastname || undefined,
            }

            const email = user.email
            if (registered) {
                onSignup({ email, ...attributes }, false)
            } else {
                onSignin(attributes, false)
            }
            context.store.commit("authentication/setIsAnonymous", false)
        } catch (err) {
            throw translateFirebaseError(err)
        }
    })

    const awaitAppleAuth = timeAsyncFunction("authentication:awaitAppleAuth", async (registered = false) => {
        const appleProvider = new firebase.auth.OAuthProvider("apple.com")
        appleProvider.addScope("email")
        appleProvider.addScope("name")
        await awaitSocialAuth(appleProvider, registered)
    })

    const awaitFbAuth = timeAsyncFunction("authentication:awaitFbAuth", async (registered = false) => {
        const facebookProvider = new firebase.auth.FacebookAuthProvider()
        facebookProvider.addScope("email")
        await awaitSocialAuth(facebookProvider, registered)
    })

    const awaitGoogleAuth = timeAsyncFunction("authentication:awaitGoogleAuth", async (registered = false) => {
        const googleProvider = new firebase.auth.GoogleAuthProvider()
        await awaitSocialAuth(googleProvider, registered)
    })

    const getProviders = () => {
        const user = context.$firebaseAuth.currentUser
        if (!!!user) {
            return []
        }
        return user.providerData.map(x => x.providerId)
    }

    const hasSocialProvider = () => {
        const socialProviders = ["google.com", "apple.com", "facebook.com"]
        return !!getProviders().find(x => socialProviders.includes(x))
    }

    const hasPasswordProvider = () => {
        return getProviders().includes("password")
    }

    const reAuthenticate = async currentPassword => {
        const user = context.$firebaseAuth.currentUser

        if (hasPasswordProvider() && !!currentPassword) {
            return await user.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(user.email, currentPassword))
        } else if (hasSocialProvider()) {
            const providers = getProviders()
            let provider = null

            if (providers.includes("google.com")) {
                provider = new firebase.auth.GoogleAuthProvider()
            } else if (providers.includes("facebook.com")) {
                provider = new firebase.auth.FacebookAuthProvider()
            } else if (providers.includes("apple.com")) {
                provider = new firebase.auth.OAuthProvider("apple.com")
            } else {
                throw "Unknown authentication provider"
            }
            return await user.reauthenticateWithPopup(provider)
        } else {
            throw "No authentication provider"
        }
    }

    const changePassword = async (currentPassword, newPassword) => {
        const user = context.$firebaseAuth.currentUser
        // Changing Firebase password requires a recent login, so we re-authenticate using
        // the customer's current password first.
        try {
            const creds = await reAuthenticate(currentPassword)
            await creds.user.updatePassword(newPassword)
        } catch (err) {
            throw translateFirebaseError(err)
        }
    }

    const changeEmail = timeAsyncFunction("authentication:changeEmail", async (currentPassword, email) => {
        const user = context.$firebaseAuth.currentUser
        // Changing Firebase email requires a recent login, so we re-authenticate using
        // the customer's current password first.
        try {
            const creds = await reAuthenticate(currentPassword)
            await creds.user.updateEmail(email)
            // TODO: this next line is not necessary, as the user object is already updated through the
            // onAuthStateChanged listener in the FirebaseAuth plugin
            context.store.commit("authentication/setUser", user)
        } catch (err) {
            throw translateFirebaseError(err)
        }
    })

    const sendPasswordResetEmail = async email => {
        const locale = context.i18n.locale

        // Set up url, so we can redirect to a localized page/domain
        let actionCodeSettings = {
            url: `${window.location.origin}/${locale}/`,
        }

        // Setting this will add the X-Firebase-Locale header to the request.
        context.$firebaseAuth.languageCode = locale

        try {
            await context.$firebaseAuth.sendPasswordResetEmail(email, actionCodeSettings)
        } catch (err) {
            // Suppress UserNotFoundError, as we don't want to leak whether an email is registered or not.
            if (err.code !== "auth/user-not-found") {
                throw translateFirebaseError(err)
            }
        }
    }

    const verifyPasswordResetCode = async code => {
        // Verify if the password reset code is expired, invalid, or already used.
        try {
            const res = await context.$firebaseAuth.verifyPasswordResetCode(code)
            return res
        } catch (err) {
            throw translateFirebaseError(err)
        }
    }

    const confirmPasswordReset = async (code, newPassword) => {
        // Reset the password with the new password and reset code.
        try {
            await context.$firebaseAuth.confirmPasswordReset(code, newPassword)
        } catch (err) {
            throw translateFirebaseError(err)
        }
    }

    inject("authentication", {
        signInWithEmailAndPassword,
        signUpWithEmail,
        promoteAnonymousUser,
        awaitAppleAuth,
        awaitFbAuth,
        awaitGoogleAuth,
        changePassword,
        changeEmail,
        linkEmailPasswordCredentials,
        unlinkProvider,
        onSignin,
        sendPasswordResetEmail,
        verifyPasswordResetCode,
        confirmPasswordReset,
    })
}
