var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"menuContainer",staticClass:"relative"},[_vm._t("activator",null,{"role":"menu"}),_vm._v(" "),_c('Animation',{class:[
            'absolute z-30',
            _vm.topHeight ? '' : _vm.top ? 'bottom-full' : 'top-full',
            {
                'left-1/2 -translate-x-1/2': !_vm.leftFull && !_vm.rightFull && !_vm.left0 && !_vm.right0,
                'left-full': _vm.leftFull,
                'right-full': _vm.rightFull,
                'left-0': _vm.left0,
                'right-0': _vm.right0,
            },
        ],style:(_vm.topHeight ? `top: ${_vm.topHeight}` : ''),attrs:{"fadeIn":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }