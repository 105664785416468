import { cloneDeep } from "lodash"
import { auth } from "@/plugins/firebaseAuth.js"

const getDefaultState = () => ({
    currentUser: null,
    initialised: false,
    isAnonymous: true,
})
export const state = () => Object.assign({}, getDefaultState())

export const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setUser(state, user) {
        state.currentUser = cloneDeep({
            uid: user.uid,
            email: user.email,
            providerData: user.providerData,
        })
        state.initialised = true
    },
    setIsAnonymous(state, isAnonymous) {
        state.isAnonymous = isAnonymous
    },
}

export const getters = {
    authenticated(state) {
        return state.initialised && state.currentUser?.uid
    },
    currentUser(state) {
        return state.currentUser
    },
}

export const actions = {
    async logout(context) {
        context.dispatch("resetState")

        const excludedKeys = ["surveys", "surveyCategories", "surveyQuestions", "surveyAnswers", "recommendations", "surveyAnswersSubmissions", "surveyAnswerRecommendationSubmissions", "languages", "countries", "languageUpdatedByUser", "breeds", "feedbackQuestions", "productCategories", "productCategoriesCache", "shippingPrices", "productTypes", "allergies", "ages", "shopCategories", "upsells"]
        for (let key in context.rootState.api) {
            if (!excludedKeys.includes(key)) {
                context.commit(`api/${key}/reset`, null, { root: true })
            }
        }
    },

    resetState(context) {
        context.commit("resetState")
    },
}
